import React, { useState } from "react";
import "antd/dist/antd.min.css";
import "./App.css";
import { Routes, Route, NavLink, Navigate } from "react-router-dom";
import bracketTextGraphic from "./assets/Bracket-Text-Graphic.png";
import logoBanner from "./assets/nurse-unit-finder-logo.png";
import headerImage from "./assets/Header-Image.png";

import StartTheStory from "./common/components/StartTheStory/StartTheStory";
import KnowTheCandidate from "./common/components/KnowTheCandidate/KnowTheCandidate";
import FindTheRightFit from "./common/components/FindTheRightFit/FindTheRightFit";
import GetToYes from "./common/components/GetToYes/GetToYes";
import CloseTheCall from "./common/components/CloseTheCall/CloseTheCall";
import TestSSO from "./common/components/Test/TestSSO";

function App() {
  const navTabs = [
    {
      id: 1,
      title: "Start the Story",
      description: "Who is your Candidate?",
      link: "/start-the-story/",
    },
    {
      id: 2,
      title: "Know the Candidate",
      description: "Context, Characters, Challenge",
      link: "/know-the-candidate",
    },
    {
      id: 3,
      title: "Find the Right Fit",
      description: "The Hero",
      link: "/find-the-right-fit",
    },
    {
      id: 4,
      title: "Get to Yes",
      description: "The Resolution",
      link: "/get-to-yes",
    },
    {
      id: 5,
      title: "Close the Call",
      description: "Lessons Learned",
      link: "/close-the-call",
    },
  ];


  // useEffect(() => {
  //   if (!isLoggedIn && ref && ref.current) {
  //ref.current.click();
  //   }
  // }, []);
  // const authRoute = async () => {
  //   console.log("called");
  //   try {
  //     //https://ccstoryapi.aspirant.com/Auth/Login/
  //     //https://localhost:7210/Auth/Login
  //     //https://localhost:7210/Auth/Assertion
  //     const response = await fetch(`https://localhost:7210/Auth/Login`, {
  //       method: "POST",
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         returnUrl: `https://localhost:7210/Auth/Assertion`,
  //       }),
  //     });
  //     const responseJSON = await response.json();
  //     console.log(responseJSON);
  //   } catch (e) {
  //     if (typeof e === "string") {
  //       console.error(e);
  //     } else if (e instanceof Error) {
  //       console.error(e.message);
  //     }
  //   }
  // };
  const isAuth = async () => {
    console.log("called");
    try {
      // LOCAL
      // const response = await fetch(`https://localhost:7210/Auth/isAuth`, {
      //   method: "GET",
      // });
      //DEPLOYED
      const response = await fetch(
        `https://ccnurseunitfinderapi.aspirant.com/Auth/isAuth`,
        {
          method: "GET",
        }
      );
      const responseJSON = await response.json();
      console.log(response);
      console.log(responseJSON);
      //setIsLoggedIn(responseJSON);
    } catch (e) {
      if (typeof e === "string") {
        // setIsLoggedIn(false);
        console.error(e);
      } else if (e instanceof Error) {
        // setIsLoggedIn(false);
        console.error(e.message);
      }
    }
  };
  const createClaim = async () => {
    console.log("called");
    try {
      // LOCAL
      // const response = await fetch(
      //   `https://localhost:7210/Auth/createSession`,
      //   {
      //     method: "GET",
      //   }
      // );
      //DEPLOYED
      const response = await fetch(
        `https://ccnurseunitfinderapi.aspirant.com/Auth/createSession`,
        {
          method: "GET",
        }
      );
      const responseJSON = await response.json();
      console.log(response);
      console.log(responseJSON);
    } catch (e) {
      if (typeof e === "string") {
        console.error(e);
      } else if (e instanceof Error) {
        console.error(e.message);
      }
    }
  };

  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

  return (
    <div className="App">
      <img src={logoBanner} className="logo-banner" alt="" />
      <img src={headerImage} className="header-image" alt="" />
      <img src={bracketTextGraphic} className="bracket-text-graphic" alt="" />

      <div className="routes-container">
        <nav className="nav">
          {navTabs.map((tab) => (
            <div className="nav-link-container" key={tab.id}>
              <NavLink
                to={tab.link}
                className={`nav-link ${({ isActive }: { isActive: boolean }) =>
                  isActive ? "active" : null}
`}
              >
                <div className="each-link-container">
                  <h3>{tab.title}</h3>
                  <p className="description">{tab.description}</p>
                  <div className="arrow-up"></div>
                </div>
                <div className="temp-divider"></div>
              </NavLink>
              <div className="divider"></div>
            </div>
          ))}
        </nav>
        <Routes>
          <Route
            path="/"
            element={<Navigate replace to="/start-the-story/" />}
          />
          <Route path="/start-the-story/" element={<StartTheStory />} />
          <Route path="/know-the-candidate/" element={<KnowTheCandidate />} />
          <Route
            path="/find-the-right-fit"
            element={<FindTheRightFit isAuth={isAuth} />}
          />
          <Route path="/get-to-yes" element={<GetToYes />} />
          <Route path="/close-the-call" element={<CloseTheCall />} />
          <Route
            path="/test-sso"
            element={
              <TestSSO
                isAuth={isAuth}
                createClaim={createClaim}
                setIsLoggedIn={setIsLoggedIn}
                isLoggedIn={isLoggedIn}
              />
            }
          />
        </Routes>
      </div>
    </div>
  );
}
export default App;
