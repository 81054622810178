export const FindTheRightFitData = [
  {
    id: 501,
    heading: "High Schedule Flexibility / High Patient Acuity",
    schedule: "high",
    acuity: "high",
    idealCandidate: [
      "Self-starter",
      "Go-getter",
      "Flexible",
      "Team-oriented",
      "Critical-thinker",
      "Can work in stressful environment",
      "Needs minimal supervision",
      "Can take appropriate action/s at the right time",
      "Personally responsible",
      "Passionate about their role",
    ],
    units: [
      {
        id: 5001,
        experience: "any",
        location: "Avon, OH",
        name: "4685 NRS-4 ICU",
        facility: "Avon Hospital",
        specialty: "ICU",
      },
      {
        id: 5002,
        experience: "any",
        location: "Avon, OH",
        name: "4688 NRS-5 Med-Surg",
        facility: "Avon Hospital",
        specialty: "Orthopaedics",
      },
      {
        id: 5003,
        experience: "any",
        location: "Akron, OH",
        name: "9618 SICU/MICU 4800",
        facility: "Akron General Medical Center",
        specialty: "ICU",
      },
      {
        id: 5004,
        experience: "any",
        location: "Akron, OH",
        name: "9441 2100 Clinical Decision Unit",
        facility: "Akron General Medical Center",
        specialty: "Observation",
      },
      {
        id: 5005,
        experience: "any",
        location: "Akron, OH",
        name: "9444 3200 CVICU Cardiovasc Intervention Care Unit",
        facility: "Akron General Medical Center",
        specialty: "ICU",
      },
      {
        id: 5006,
        experience: "experienced",
        location: "Green, OH",
        name: "9507 Emergency Department",
        facility: "Health and Wellness Green",
        specialty: "Emergency",
      },
      {
        id: 5007,
        experience: "experienced",
        location: "Weston, FL",
        name: "3865 Cardiac Cath Lab",
        facility: "CC Florida Weston",
        specialty: "Cardiac",
      },
      {
        id: 5008,
        experience: "experienced",
        location: "Weston, FL",
        name: "2787 PACU",
        facility: "CC Florida Weston",
        specialty: "Post-Surgical",
      },
      {
        id: 5009,
        experience: "experienced",
        location: "Weston, FL",
        name: "3748 Interventional Radiology",
        facility: "CC Florida Weston",
        specialty: "Imaging",
      },
      {
        id: 5010,
        experience: "any",
        location: "Vero Beach, FL",
        name: "6138 BCH ADMINISTRATION A R",
        facility: "Cleveland Clinic Indian River",
        specialty: "Behavioral Health",
      },
      {
        id: 5011,
        experience: "any",
        location: "Vero Beach, FL",
        name: "7231 ANCILLARY-EMERGENCY DEPT",
        facility: "Cleveland Clinic Indian River",
        specialty: "Emergency",
      },
      {
        id: 5012,
        experience: "any",
        location: "Stuart, FL",
        name: "6017 MMC Nursing Administration",
        facility: "Cleveland Clinic Martin Health",
        specialty: ["Hematology Oncology", "Stroke"],
      },
      {
        id: 5013,
        experience: "any",
        location: "port st. lucie",
        name: "6151 TMC Labor And Delivery",
        facility: "Cleveland Clinic Martin Health",
        specialty: "Labor and Delivery",
      },
      {
        id: 5014,
        experience: "experienced",
        location: "Stuart, FL",
        name: "9035 Treasure Coast Integrated Hlth",
        facility: "Cleveland Clinic Martin Health",
        specialty: "Chronic Disease",
      },
      {
        id: 5015,
        experience: "any",
        location: "Stuart, FL",
        name: "6150 MMC Labor And Delivery",
        facility: "Cleveland Clinic Martin Health",
        specialty: "Labor and Delivery",
      },
      {
        id: 5016,
        experience: "any",
        location: "Canton, OH",
        name: "1703 Open Heart Surgery Unit CVSOR",
        facility: "Cleveland Clinic Mercy Hospital",
        specialty: "Cardiac",
      },
      {
        id: 5017,
        experience: "any",
        location: "Canton, OH",
        name: "1630 9M Transitional Care Unit",
        facility: "Cleveland Clinic Mercy Hospital",
        specialty: "Telemetry",
      },
      {
        id: 5018,
        experience: "any",
        location: "Canton, OH",
        name: "1646 Adult Telemetry and Medical Surgical",
        facility: "Cleveland Clinic Mercy Hospital",
        specialty: "Medical Telemetry",
      },
      {
        id: 5019,
        experience: "any",
        location: "Dover, OH",
        name: "6020- Nursing Administration",
        facility: "Cleveland Clinic Union Hospital",
        specialty: "Travel and Float",
      },
      {
        id: 5020,
        experience: "experienced",
        location: "Cleveland, OH",
        name: "1923 Cole Eye Or",
        facility: "Cleveland Clinic Main Campus",
        specialty: "Surgery",
      },
      {
        id: 5021,
        experience: "experienced",
        location: "Twinsburg, OH",
        name: "1557 Twinsburg Em Ser Admin",
        facility: "Twinsburg Family Hlth Ctr",
        specialty: "Emergency",
      },
      {
        id: 5022,
        experience: "experienced",
        location: "Brunswick, OH",
        name: "4416 Emergency Services-Brunswick",
        facility: "Brunswick Family Hlth Ctr",
        specialty: "Emergency",
      },
      {
        id: 5023,
        experience: "any",
        location: "Euclid, OH",
        name: "1800 Emergency Department",
        facility: "Euclid Hospital",
        specialty: "Emergency",
      },
      {
        id: 5024,
        experience: "any",
        location: "Euclid, OH",
        name: "2856 Med Surg 5Th Floor",
        facility: "Euclid Hospital",
        specialty: "Orthopaedics",
      },
      {
        id: 5025,
        experience: "any",
        location: "Euclid, OH",
        name: "3008 Behavioral Medicine I/P",
        facility: "Euclid Hospital",
        specialty: "Behavioral Health",
      },
      {
        id: 5026,
        experience: "any",
        location: "Cleveland, OH",
        name: "4496 NRS Surgical ICU",
        facility: "Fairview Hospital",
        specialty: "ICU",
      },
      {
        id: 5027,
        experience: "any",
        location: "Cleveland, OH",
        name: "4582 NRS-2 South-CDU",
        facility: "Fairview Hospital",
        specialty: "Medical Telemetry",
      },
      {
        id: 5028,
        experience: "experienced",
        location: "Cleveland, OH",
        name: "3820 Electrophysiology",
        facility: "Cleveland Clinic Main Campus",
        specialty: "Cardiac",
      },
      {
        id: 5029,
        experience: "any",
        location: "Mayfeild Heights, OH",
        name: "1804 Emergency Department",
        facility: "Hillcrest Hospital",
        specialty: "Emergency",
      },
      {
        id: 5030,
        experience: "any",
        location: "Mayfeild Heights, OH",
        name: "2001 3 Seidman",
        facility: "Hillcrest Hospital",
        specialty: "Hematology Oncology",
      },
      {
        id: 5031,
        experience: "any",
        location: "Mayfeild Heights, OH",
        name: "2003 5 Seidman",
        facility: "Hillcrest Hospital",
        specialty: "Renal",
      },
      {
        id: 5032,
        experience: "any",
        location: "Mayfeild Heights, OH",
        name: "2748 Coronary Care Unit",
        facility: "Hillcrest Hospital",
        specialty: "Cardiac",
      },
      {
        id: 5033,
        experience: "any",
        location: "Mayfeild Heights, OH",
        name: "2858 Med/Surg 5Th Floor",
        facility: "Hillcrest Hospital",
        specialty: "Medical Telemetry",
      },
      {
        id: 5034,
        experience: "any",
        location: "Mayfeild Heights, OH",
        name: "3044 Neonatal Services",
        facility: "Hillcrest Hospital",
        specialty: "ICU",
      },
      {
        id: 5035,
        experience: "any",
        location: "Cleveland, OH",
        name: "1805 Emergency Room",
        facility: "Lutheran Hospital",
        specialty: "Emergency",
      },
      {
        id: 5036,
        experience: "any",
        location: "Cleveland, OH",
        name: "2063 5d General Surgery",
        facility: "Lutheran Hospital",
        specialty: "Post-Surgical",
      },
      {
        id: 5037,
        experience: "any",
        location: "garfeild",
        name: "1808 Emergency Services",
        facility: "Marymount Hospital",
        specialty: "Emergency",
      },
      {
        id: 5038,
        experience: "any",
        location: "garfeild",
        name: "1972 Gero Psych",
        facility: "Marymount Hospital",
        specialty: "Behavioral Health",
      },
      {
        id: 5039,
        experience: "any",
        location: "garfeild",
        name: "2854 4 East/4 West",
        facility: "Marymount Hospital",
        specialty: "Medical Telemetry",
      },
      {
        id: 5040,
        experience: "any",
        location: "garfeild",
        name: "2946 Behav Med Ground Floor",
        facility: "Marymount Hospital",
        specialty: "Behavioral Health",
      },
      {
        id: 5041,
        experience: "experienced",
        location: "Medina, OH",
        name: "2785 PACU",
        facility: "Medina Hospital",
        specialty: "Surgery",
      },
      {
        id: 5042,
        experience: "experienced",
        location: "Medina, OH",
        name: "3861 Cardiac Cath Lab",
        facility: "Medina Hospital",
        specialty: "Surgery",
      },
      {
        id: 5043,
        experience: "any",
        location: "Medina, OH",
        name: "4842 Two East",
        facility: "Medina Hospital",
        specialty: "Orthopaedics",
      },
      {
        id: 5044,
        experience: "any",
        location: "Cleveland, OH",
        name: "1695 M40 Pediatrics",
        facility: "Cleveland Clinic Main Campus",
        specialty: "Peds ICU",
      },
      {
        id: 5045,
        experience: "any",
        location: "Cleveland, OH",
        name: "1802 ED CCF",
        facility: "Cleveland Clinic Main Campus",
        specialty: "Emergency",
      },
      {
        id: 5046,
        experience: "any",
        location: "Cleveland, OH",
        name: "2221 J3-1 Coronary Icu",
        facility: "Cleveland Clinic Main Campus",
        specialty: "ICU",
      },
      {
        id: 5047,
        experience: "new",
        location: "Cleveland, OH",
        name: "2252 J8-2 Heart/Lung Transplant",
        facility: "Cleveland Clinic Main Campus",
        specialty: "Transplant",
      },
      {
        id: 5048,
        experience: "any",
        location: "Cleveland, OH",
        name: "2729 G52/G53/G54 SICU",
        facility: "Cleveland Clinic Main Campus",
        specialty: "ICU",
      },
      {
        id: 5049,
        experience: "any",
        location: "Cleveland, OH",
        name: "2734 G60/G61 Micu",
        facility: "Cleveland Clinic Main Campus",
        specialty: "ICU",
      },
      {
        id: 5050,
        experience: "any",
        location: "Cleveland, OH",
        name: "2741 G62- MICU",
        facility: "Cleveland Clinic Main Campus",
        specialty: "ICU",
      },
      {
        id: 5051,
        experience: "any",
        location: "Cleveland, OH",
        name: "2750 H62/H63 Neuro Stepdown",
        facility: "Cleveland Clinic Main Campus",
        specialty: "Neuro Stepdown",
      },
      {
        id: 5052,
        experience: "any",
        location: "Cleveland, OH",
        name: "2816 H71 Ddi Surg Ortho",
        facility: "Cleveland Clinic Main Campus",
        specialty: "Post-Surgical",
      },
      {
        id: 5053,
        experience: "any",
        location: "Cleveland, OH",
        name: "2875 G71",
        facility: "Cleveland Clinic Main Campus",
        specialty: "Women's Health",
      },
      {
        id: 5054,
        experience: "any",
        location: "Cleveland, OH",
        name: "2891 G70 Hematology Oncology",
        facility: "Cleveland Clinic Main Campus",
        specialty: "Hematology Oncology",
      },
      {
        id: 5055,
        experience: "any",
        location: "Cleveland, OH",
        name: "2980 G110 Bone Marrow Transplant",
        facility: "Cleveland Clinic Main Campus",
        specialty: "Bone Marrow Transplant",
      },
      {
        id: 5056,
        experience: "experienced",
        location: "Beachwood, OH",
        name: "2687 Central Monitoring Unit",
        facility: "CC Administrative Campus",
        specialty: "Telemetry",
      },
      {
        id: 5057,
        experience: "any",
        location: "Cleveland, OH",
        name: "1996 M33 Pediatrics Infant",
        facility: "Cleveland Clinic Main Campus",
        specialty: "Peds ICU",
      },
      {
        id: 5058,
        experience: "any",
        location: "Cleveland, OH",
        name: "2131 G101 Specialty Care Trans",
        facility: "Cleveland Clinic Main Campus",
        specialty: "Transplant",
      },
      {
        id: 5059,
        experience: "any",
        location: "Cleveland, OH",
        name: "2866 H70 Orthopedics",
        facility: "Cleveland Clinic Main Campus",
        specialty: "Orthopaedics",
      },
      {
        id: 5061,
        experience: "experienced",
        location: "Cleveland, OH",
        name: "2874 M81 Short Stay",
        facility: "Cleveland Clinic Main Campus",
        specialty: "Post-Surgical",
      },
      {
        id: 5062,
        experience: "experienced",
        location: "Cleveland, OH",
        name: "3136 M24 Special Delivery Unit",
        facility: "Cleveland Clinic Main Campus",
        specialty: "Labor and Delivery",
      },
      {
        id: 5063,
        experience: "experienced",
        location: "Cleveland, OH",
        name: "3357 Woc Nursing",
        facility: "Cleveland Clinic Main Campus",
        specialty: "Renal",
      },
      {
        id: 5064,
        experience: "any",
        location: "Cleveland, OH",
        name: "1062 Rheumatic Disease",
        facility: "Cleveland Clinic Main Campus",
        specialty: "Chronic Disease",
      },
      {
        id: 5065,
        experience: "any",
        location: "Cleveland, OH",
        name: "3143 Nursing",
        facility: "Childrens Hospital Rehab",
        specialty: "Children's Rehab",
      },
      {
        id: 5066,
        experience: "any",
        location: "Warrensville Heights, OH",
        name: "2167 GI Endoscopy",
        facility: "South Pointe Hospital",
        specialty: "Surgery",
      },
      {
        id: 5067,
        experience: "any",
        location: "Warrensville Heights, OH",
        name: "2842 Progressive Cardiac Care Unit",
        facility: "South Pointe Hospital",
        specialty: "Cardiology & Heart Failure Stepdown",
      },
      {
        id: 5068,
        experience: "any",
        location: "Beachwood, OH",
        name: "1030 Beachwood ASC-Admin",
        facility: "Beachwood Fam Hlth Surgery Ctr",
        specialty: "Surgery",
      },
      //F
      {
        id: 5069,
        experience: "experienced",
        location: "Cleveland, OH",
        name: "4412 Imaging Nursing",
        facility: "Cleveland Clinic Main Campus",
        specialty: "Imaging",
      },
      {
        id: 5070,
        experience: "any",
        location: "Avon, OH",
        name: "4684 NRS-3 Med-Surg",
        facility: "Avon Hospital",
        specialty: "Medical Telemetry",
      },
      // {
      //   id: 5071,
      //   experience: "any",
      //   location: "Avon, OH",
      //   name: "4684 NRS-3 Med-Surg",
      //   level: "none",
      // },
      {
        id: 5072,
        experience: "any",
        location: "Weston, FL",
        name: "1811 Emergency Dept",
        facility: "CC Florida Weston",
        specialty: "Emergency",
      },
      {
        id: 5073,
        experience: "any",
        location: "Vero Beach, FL",
        name: "7227 ANCILLARY-ONCOLOGY INFUSION",
        facility: "Cleveland Clinic Indian River",
        specialty: "Hematology Oncology",
      },
      {
        id: 5074,
        experience: "any",
        location: "Lakewood, OH",
        name: "4515 Lakewood Emergency Dept",
        facility: "Lakewood Family Health Center",
        specialty: "Emergency",
      },
      {
        id: 5075,
        experience: "any",
        location: "Euclid, OH",
        name: "3037 Subacute Care Center Snf",
        facility: "Euclid Hospital",
        specialty: "Sub-Acute & Rehab",
      },
      {
        id: 5076,
        experience: "any",
        location: "Mayfeild Heights, OH",
        name: "2876 Med/Surg 3Rd Floor West",
        facility: "Hillcrest Hospital",
        specialty: "Post-Surgical",
      },
      {
        id: 5077,
        experience: "experienced",
        location: "Cleveland, OH",
        name: "4413 Regional Imaging Nursing",
        facility: "Fairview Hospital",
        specialty: "Imaging",
      },
      {
        id: 5078,
        experience: "any",
        location: "Warrensville Heights, OH",
        name: "2838 Observation Unit",
        facility: "South Pointe Hospital",
        specialty: "Observation",
      },
    ],
  },
  {
    id: 502,
    heading: "High Schedule Flexibility / Low Patient Acuity",
    schedule: "high",
    acuity: "low",
    idealCandidate: [
      "Flexible",
      "Able to monitor and revise plan of care",
      "Can easily carry out Healthcare Providers' orders",
      "Critical-thinker",
      "Decisive judgement skills",
      "Can work in variety of environments",
      "Patient-educator",
    ],
    units: [
      {
        id: 5079,
        experience: "experienced",
        location: "Cleveland, OH",
        name: "3517 Pain Management Ctr",
        facility: "Cleveland Clinic Main Campus",
        specialty: "Surgery",
      },
      {
        id: 5080,
        experience: "experienced",
        location: "Vero Beach, FL",
        name: "6212 DERMATOLOGY PRACTICE",
        facility: "Cleveland Clinic Indian River",
        specialty: "Other non-ICU area",
      },
      {
        id: 5081,
        experience: "experienced",
        location: "Cleveland, OH",
        name: "2402 Plastic Surgery",
        facility: "Cleveland Clinic Indian River",
        specialty: "Surgery",
      },
      {
        id: 5082,
        experience: "experienced",
        location: "Mayfeild Heights, OH",
        name: "4459 DDSI CIG",
        facility: "Landerbrook Ofc and Endo Ctr",
        specialty: "Digestive Disease",
      },
      {
        id: 5084,
        experience: "experienced",
        location: "Westlake, OH",
        name: "4609 DDSI/NOG",
        facility: "North Ohio Gastroenterology",
        specialty: "Digestive Disease",
      },
      {
        id: 5085,
        experience: "experienced",
        location: "Norton, OH",
        name: "4796 DDSI/Summit GI",
        facility: "Summit GI",
        specialty: "Digestive Disease",
      },
      {
        id: 5086,
        experience: "experienced",
        location: "Cleveland, OH",
        name: "1995 Childbirth/Childcare",
        facility: "Fairview Hospital",
        specialty: "Women's Health",
      },
      {
        id: 5087,
        experience: "any",
        location: "Mayfeild Heights, OH",
        name: "3054 Childbirth Education",
        facility: "Hillcrest Hospital",
        specialty: "Women's Health",
      },
      {
        id: 5088,
        experience: "experienced",
        location: "Garfield Heights, OH",
        name: "2997 ASC Surgery",
        facility: "Marymount Hospital",
        specialty: "Surgery",
      },
      {
        id: 5089,
        experience: "experienced",
        location: "Medina, OH",
        name: "4630 Eye-Regnl Akron-Park West",
        facility: "Medina Hospital",
        specialty: "Surgery",
      },
      {
        id: 5090,
        experience: "any",
        location: "Cleveland, OH",
        name: "1801 Emergency Services",
        facility: "Cleveland Clinic Main Campus",
        specialty: "Emergency",
      },
      {
        id: 5091,
        experience: "any",
        location: "Cleveland, OH",
        name: "3097 OB/GYN",
        facility: "Cleveland Clinic Main Campus",
        specialty: "Women's Health",
      },
      {
        id: 5092,
        experience: "any",
        location: "Cleveland, OH",
        name: "1736 Peds-General",
        facility: "Cleveland Clinic Main Campus",
        specialty: "Medical Surgical",
      },
      {
        id: 5094,
        experience: "experienced",
        location: "Lakewood, OH",
        name: "1546 Lakewood Administration",
        facility: "Lakewood Family Health Center",
        specialty: "Ambulatory",
      },
      {
        id: 5095,
        experience: "experienced",
        location: "Medina, OH",
        name: "1555 Medina Mob Admin",
        facility: "Medina Medical Office Building",
        specialty: "Other non-ICU area",
      },
      {
        id: 5096,
        experience: "experienced",
        location: "Cleveland, OH",
        name: "3394 Pulmonary Medicine",
        facility: "Cleveland Clinic Main Campus",
        specialty: ["Respiratory Stepdown", "Ambulatory"],
      },
      {
        id: 5097,
        experience: "experienced",
        location: "Twinsburg, OH",
        name: "1566 Twinsburg Asc-Admin",
        facility: "Twinsburg Family Hlth Ctr",
        specialty: "Surgery",
      },
      {
        id: 5098,
        experience: "experienced",
        location: "Sandusky, OH",
        name: "2585 NCCC Administration",
        facility: "NCCC Sandusky Campus",
        specialty: "Hematology Oncology",
      },
      {
        id: 5099,
        experience: "any",
        location: "Weston, FL",
        name: "2728 Nursing Administration",
        facility: "CC Florida Weston",
        specialty: [
          "Ambulatory",
          "Hematology Oncology",
          "Observation",
          "Other non-ICU area",
        ],
      },
      {
        id: 5100,
        experience: "experienced",
        location: "Cleveland, OH",
        name: "2523 DDSI Administration",
        facility: "Cleveland Clinic Main Campus",
        specialty: "Women's Health",
      },
      {
        id: 5101,
        experience: "experienced",
        location: "Mayfeild Heights, OH",
        name: "4338 Cardiovascular Med Region",
        facility: "Hillcrest Hospital",
        specialty: "Cardiac",
      },
      {
        id: 5102,
        experience: "experienced",
        location: "Cleveland, OH",
        name: "1008 Same Day Surgery",
        facility: "Cleveland Clinic Main Campus",
        specialty: "Post-Surgical",
      },
      {
        id: 5103,
        experience: "experienced",
        location: "Akron, OH",
        name: "9555 Nursing Administration",
        facility: "Akron General Medical Center",
        specialty: "Other non-ICU area",
      },
      {
        id: 5104,
        experience: "experienced",
        location: "Stuart, FL",
        name: "6185 MMC Maternal Child OP Srvs",
        facility: "Cleveland Clinic Martin Health",
        specialty: "Women's Health",
      },
    ],
  },
  {
    id: 503,
    heading: "Medium Schedule Flexibility / High Patient Acuity",
    schedule: "medium",
    acuity: "high",
    idealCandidate: [
      "Great communicator",
      "Ability to interact with multidisciplinary teams",
      "Can develop plan of care w/ team",
      "Able to evaluate and implement patient/family learning needs",
      "High self-confidence in skills and experience-driven recommendations",
      "Self development-oriented",
    ],
    units: [
      {
        id: 5105,
        experience: "any",
        location: "Avon, OH",
        name: "4689 NRS-Emergency Dept",
        facility: "Avon Hospital",
        specialty: "Emergency",
      },
      {
        id: 5106,
        experience: "experienced",
        location: "Avon, OH",
        name: "4722 Surgery Administration",
        facility: "Avon Hospital",
        specialty: ["Emergency", "Surgery"],
      },
      {
        id: 5107,
        experience: "any",
        location: "Akron, OH",
        name: "9447 4100 Medical Cardiology Unit",
        facility: "Akron General Medical Center",
        specialty: "Cardiac",
      },
      {
        id: 5108,
        experience: "any",
        location: "Akron, OH",
        name: "9448 4200 CV Surg Acute Cardiology",
        facility: "Akron General Medical Center",
        specialty: "Cardiac",
      },
      {
        id: 5109,
        experience: "any",
        location: "Akron, OH",
        name: "9561 Operating Room",
        facility: "Akron General Medical Center",
        specialty: "Surgery",
      },
      {
        id: 5110,
        experience: "experienced",
        location: "Akron, OH",
        name: "9571 Perioperative Services",
        facility: "Akron General Medical Center",
        specialty: "Surgery",
      },
      {
        id: 5111,
        experience: "any",
        location: "Akron, OH",
        name: "9507 Emergency Department",
        facility: "Akron General Medical Center",
        specialty: "Emergency",
      },
      {
        id: 5113,
        experience: "experienced",
        location: "Akron, OH",
        name: "9537 Labor And Delivery",
        facility: "Akron General Medical Center",
        specialty: "Labor and Delivery",
      },
      {
        id: 5114,
        experience: "any",
        location: "Akron, OH",
        name: "9620 Special Care Nursery",
        facility: "Akron General Medical Center",
        specialty: "ICU",
      },
      {
        id: 5115,
        experience: "experienced",
        location: "stow",
        name: "9507 Emergency Department",
        facility: "Health and Wellness Stow",
        specialty: "ICU",
      },
      {
        id: 5116,
        experience: "any",
        location: "Weston, FL",
        name: "2075 Operating Room Acc",
        facility: "CC Florida Weston",
        specialty: "Surgery",
      },
      {
        id: 5117,
        experience: "experienced",
        location: "Weston, FL",
        name: "2739 SICU",
        facility: "CC Florida Weston",
        specialty: ["ICU", "Transplant"],
      },
      {
        id: 5118,
        experience: "experienced",
        location: "Weston, FL",
        name: "2843 Telemetry",
        facility: "CC Florida Weston",
        specialty: "Telemetry",
      },
      {
        id: 5119,
        experience: "any",
        location: "Weston, FL",
        name: "2850 Med Surg 3Rd Floor",
        facility: "CC Florida Weston",
        specialty: "Medical Telemetry",
      },
      {
        id: 5120,
        experience: "any",
        location: "Vero Beach, FL",
        name: "6050 NURSE-CARDIAC STEP DOWN TELE-5E",
        facility: "Cleveland Clinic Indian River",
        specialty: "Cardiac Surgery Stepdown",
      },
      {
        id: 5121,
        experience: "any",
        location: "Vero Beach, FL",
        name: "6060 NURSE-INTERM CARDIAC CARE-3 S 3 N",
        facility: "Cleveland Clinic Indian River",
        specialty: "Progressive Care Cardiac",
      },
      {
        id: 5122,
        experience: "any",
        location: "Vero Beach, FL",
        name: "6078 NURSE-ONCOLOGY 5 N",
        facility: "Cleveland Clinic Indian River",
        specialty: "Hematology Oncology",
      },
      {
        id: 5123,
        experience: "any",
        location: "port st. lucie",
        name: "6010 TMC Nursing Administration",
        facility: "Cleveland Clinic Indian River",
        specialty: ["Emergency", "ICU", "Neuro Med/Surg"],
      },
      {
        id: 5124,
        experience: "any",
        location: "Stuart, FL",
        name: "6018 MMS Nursing Administration",
        facility: "Cleveland Clinic Martin Health",
        specialty: ["Emergency", "Medical Telemetry"],
      },
      {
        id: 5125,
        experience: "any",
        location: "Stuart, FL",
        name: "6166 TMC NICU",
        facility: "Cleveland Clinic Martin Health",
        specialty: "Peds ICU",
      },
      {
        id: 5126,
        experience: "any",
        location: "Canton, OH",
        name: "1632 7M Medical",
        facility: "Cleveland Clinic Mercy Hospital",
        specialty: "Medical Telemetry",
      },
      {
        id: 5127,
        experience: "experienced",
        location: "Canton, OH",
        name: "1635 Cardiovascular Surgical Care",
        facility: "Cleveland Clinic Mercy Hospital",
        specialty: "Cardiac Surgery Stepdown",
      },
      {
        id: 5128,
        experience: "any",
        location: "Canton, OH",
        name: "1614 Medical and Surgical Unit 2M",
        facility: "Cleveland Clinic Mercy Hospital",
        specialty: "Surgery",
      },
      {
        id: 5129,
        experience: "any",
        location: "Canton, OH",
        name: "1620 Medical and Surgical Unit 10M",
        facility: "Cleveland Clinic Mercy Hospital",
        specialty: "Respiratory Stepdown",
      },
      {
        id: 5130,
        experience: "any",
        location: "Canton, OH",
        name: "1650 Intensive Care Unit",
        facility: "Cleveland Clinic Mercy Hospital",
        specialty: "ICU",
      },
      {
        id: 5131,
        experience: "experienced",
        location: "Canton, OH",
        name: "1750 Cardiac Diagnostics",
        facility: "Cleveland Clinic Mercy Hospital",
        specialty: "Cardiac",
      },
      {
        id: 5132,
        experience: "any",
        location: "Dover, OH",
        name: "6400- ICU",
        facility: "Cleveland Clinic Union Hospital",
        specialty: "ICU",
      },
      {
        id: 5133,
        experience: "any",
        location: "Dover, OH",
        name: "6600- OR",
        facility: "Cleveland Clinic Union Hospital",
        specialty: "ICU",
      },
      {
        id: 5134,
        experience: "any",
        location: "Cleveland, OH",
        name: "1272 Emergency Services",
        facility: "Fairview Hospital",
        specialty: "Emergency",
      },
      {
        id: 5135,
        experience: "experienced",
        location: "Cleveland, OH",
        name: "1992 4 Antepartum",
        facility: "Fairview Hospital",
        specialty: "Women's Health",
      },
      {
        id: 5136,
        experience: "any",
        location: "Cleveland, OH",
        name: "2747 Kemper Ccu",
        facility: "Fairview Hospital",
        specialty: "ICU",
      },
      {
        id: 5137,
        experience: "any",
        location: "Cleveland, OH",
        name: "2821 Parkview Iii",
        facility: "Fairview Hospital",
        specialty: "Post-Surgical",
      },
      {
        id: 5138,
        experience: "any",
        location: "Cleveland, OH",
        name: "4495 NRS Medical ICU",
        facility: "Fairview Hospital",
        specialty: "ICU",
      },
      {
        id: 5139,
        experience: "experienced",
        location: "Cleveland, OH",
        name: "1979 5 PAVILION",
        facility: "Fairview Hospital",
        specialty: "Medical Telemetry",
      },
      {
        id: 5140,
        experience: "experienced",
        location: "Cleveland, OH",
        name: "2742 NICU",
        facility: "Fairview Hospital",
        specialty: "ICU",
      },
      {
        id: 5141,
        experience: "experienced",
        location: "Cleveland, OH",
        name: "2779 PACU",
        facility: "Fairview Hospital",
        specialty: "Surgery",
      },
      {
        id: 5141,
        experience: "any",
        location: "Cleveland, OH",
        name: "2819 Parkview I",
        facility: "Fairview Hospital",
        specialty: "Medical Telemetry",
      },
      {
        id: 5142,
        experience: "any",
        location: "Cleveland, OH",
        name: "2820 Parkview Ii",
        facility: "Fairview Hospital",
        specialty: "Medical Telemetry",
      },
      {
        id: 5143,
        experience: "any",
        location: "Cleveland, OH",
        name: "2857 5 Med Surg",
        facility: "Fairview Hospital",
        specialty: "Medical Telemetry",
      },
      {
        id: 5144,
        experience: "experienced",
        location: "Cleveland, OH",
        name: "2923 Oncology Program",
        facility: "Fairview Hospital",
        specialty: "Hematology Oncology",
      },
      {
        id: 5145,
        experience: "experienced",
        location: "Cleveland, OH",
        name: "3858 Cardiac Cath Lab",
        facility: "Fairview Hospital",
        specialty: "Cardiology & Heart Failure Stepdown",
      },
      {
        id: 5146,
        experience: "experienced",
        location: "Cleveland, OH",
        name: "4335 Intervention",
        facility: "Cleveland Clinic Main Campus",
        specialty: "Cardiac",
      },
      {
        id: 5147,
        experience: "any",
        location: "Mayfeild Heights, OH",
        name: "1897 Pediatric Emergency Dept",
        facility: "Hillcrest Hospital",
        specialty: "Peds Emergency",
      },
      {
        id: 5148,
        experience: "any",
        location: "Mayfeild Heights, OH",
        name: "2735 ICU",
        facility: "Hillcrest Hospital",
        specialty: "ICU",
      },
      {
        id: 5149,
        experience: "experienced",
        location: "Mayfeild Heights, OH",
        name: "2044 Surgery Center",
        facility: "Hillcrest Hospital",
        specialty: "Post-Surgical",
      },
      {
        id: 5150,
        experience: "experienced",
        location: "Mayfeild Heights, OH",
        name: "2474 Dialysis Intensive Care Unit",
        facility: "Hillcrest Hospital",
        specialty: "Renal",
      },
      {
        id: 5151,
        experience: "experienced",
        location: "Mayfeild Heights, OH",
        name: "2916 Ambulatory Cancer Center",
        facility: "Hillcrest Hospital",
        specialty: "Hematology Oncology",
      },
      {
        id: 5152,
        experience: "any",
        location: "Cleveland, OH",
        name: "2763 Nursing Operating Room",
        facility: "Lutheran Hospital",
        specialty: "Surgery",
      },
      {
        id: 5153,
        experience: "any",
        location: "Cleveland, OH",
        name: "2963 Trans Senior Mental Health 6D",
        facility: "Lutheran Hospital",
        specialty: "Behavioral Health",
      },
      {
        id: 5154,
        experience: "any",
        location: "Cleveland, OH",
        name: "2965 Adult Psych 3B",
        facility: "Lutheran Hospital",
        specialty: "Behavioral Health",
      },
      {
        id: 5155,
        experience: "experienced",
        location: "Cleveland, OH",
        name: "2781 Nursing Pacu",
        facility: "Lutheran Hospital",
        specialty: "Orthopaedics",
      },
      {
        id: 5156,
        experience: "any",
        location: "Cleveland, OH",
        name: "4299 4D Medical Unit",
        facility: "Lutheran Hospital",
        specialty: "ICU",
      },
      {
        id: 5157,
        experience: "experienced",
        location: "Garfield Heights, OH",
        name: "2752 Perioperative Admin",
        facility: "Marymount Hospital",
        specialty: "Surgery",
      },
      {
        id: 5158,
        experience: "any",
        location: "Garfield Heights, OH",
        name: "2737 ICU/CCU",
        facility: "Marymount Hospital",
        specialty: "ICU",
      },
      {
        id: 5159,
        experience: "any",
        location: "Garfield Heights, OH",
        name: "2776 PACU",
        facility: "Marymount Hospital",
        specialty: "Post-Surgical",
      },
      {
        id: 5161,
        experience: "experienced",
        location: "Cleveland, OH",
        name: "2745 Critical Care Transport",
        facility: "Cleveland Clinic Main Campus",
        specialty: "ICU",
      },
      {
        id: 5162,
        experience: "any",
        location: "Medina, OH",
        name: "2827 Two North",
        facility: "Medina Hospital",
        specialty: "Neuro Stepdown",
      },
      {
        id: 5163,
        experience: "any",
        location: "Medina, OH",
        name: "1806 Emergency",
        facility: "Medina Hospital",
        specialty: "Emergency",
      },
      {
        id: 5164,
        experience: "experienced",
        location: "Medina, OH",
        name: "2065 Operating Room",
        facility: "Medina Hospital",
        specialty: "Surgery",
      },
      {
        id: 5165,
        experience: "any",
        location: "Medina, OH",
        name: "2731 Surgical ICU",
        facility: "Medina Hospital",
        specialty: "ICU",
      },
      {
        id: 5166,
        experience: "any",
        location: "Medina, OH",
        name: "2828 Three South",
        facility: "Medina Hospital",
        specialty: "Medical Telemetry",
      },
      {
        id: 5167,
        experience: "any",
        location: "Cleveland, OH",
        name: "1571 G80 General Internal Medicine",
        facility: "Cleveland Clinic Main Campus",
        specialty: "Medical Telemetry",
      },
      {
        id: 5168,
        experience: "any",
        location: "Cleveland, OH",
        name: "1751 M43/M53 Peds Icu",
        facility: "Cleveland Clinic Main Campus",
        specialty: "Peds ICU",
      },
      {
        id: 5169,
        experience: "experienced",
        location: "Cleveland, OH",
        name: "2000 M31 Neonatal Icu",
        facility: "Cleveland Clinic Main Campus",
        specialty: "ICU",
      },
      {
        id: 5170,
        experience: "any",
        location: "Cleveland, OH",
        name: "2042 G91 Endopulmrescu",
        facility: "Cleveland Clinic Main Campus",
        specialty: "Respiratory Stepdown",
      },
      {
        id: 5171,
        experience: "new",
        location: "Cleveland, OH",
        name: "2222 J5-5 Cardiovas Surg ICU",
        facility: "Cleveland Clinic Main Campus",
        specialty: "Cardiac",
      },
      {
        id: 5172,
        experience: "new",
        location: "Cleveland, OH",
        name: "2223 J6-5 Cardiovas Surg ICU",
        facility: "Cleveland Clinic Main Campus",
        specialty: "ICU",
      },
      {
        id: 5173,
        experience: "any",
        location: "Cleveland, OH",
        name: "2248 J6-3 Cardiovascular Stepdown 3",
        facility: "Cleveland Clinic Main Campus",
        specialty: "Cardiac Surgery Stepdown",
      },
      {
        id: 5174,
        experience: "any",
        location: "Cleveland, OH",
        name: "2815 G81 Nephrologyentderm",
        facility: "Cleveland Clinic Main Campus",
        specialty: "Medical Telemetry",
      },
      {
        id: 5175,
        experience: "any",
        location: "Cleveland, OH",
        name: "2818 H81 Internal Med Telemetry",
        facility: "Cleveland Clinic Main Campus",
        specialty: "Medical Telemetry",
      },
      {
        id: 5176,
        experience: "any",
        location: "Cleveland, OH",
        name: "2872 G20/H22 Neuro ICU",
        facility: "Cleveland Clinic Main Campus",
        specialty: "Neuro Med Surg",
      },
      {
        id: 5177,
        experience: "any",
        location: "Cleveland, OH",
        name: "1999 M52 Pediatric Epilespy",
        facility: "Cleveland Clinic Main Campus",
        specialty: "Neuro Med Surg",
      },
      {
        id: 5178,
        experience: "any",
        location: "Cleveland, OH",
        name: "2233 J3-3 Cardiac Short Stay Unit",
        facility: "Cleveland Clinic Main Campus",
        specialty: "Cardiac Short Stay",
      },
      {
        id: 5179,
        experience: "any",
        location: "Cleveland, OH",
        name: "2243 J7-1 Cardiology Stepdown",
        facility: "Cleveland Clinic Main Campus",
        specialty: "Cardiology & Heart Failure Stepdown",
      },
      {
        id: 5179,
        experience: "any",
        location: "Cleveland, OH",
        name: "2245 J7-2 Cardiology Stepdown",
        facility: "Cleveland Clinic Main Campus",
        specialty: "Cardiology & Heart Failure Stepdown",
      },
      {
        id: 5180,
        experience: "any",
        location: "Cleveland, OH",
        name: "2830 M70/M71 Lymphoma/Myeloma",
        facility: "Cleveland Clinic Main Campus",
        specialty: "Hematology Oncology",
      },
      {
        id: 5181,
        experience: "experienced",
        location: "Cleveland, OH",
        name: "2902 Peds Hematology/Oncology",
        facility: "Cleveland Clinic Main Campus",
        specialty: "Ambulatory",
      },
      {
        id: 5182,
        experience: "experienced",
        location: "Willoughby Hills, OH",
        name: "1552 Will Hills Administration",
        facility: "Willoughby Hills Fam Hlth Ctr",
        specialty: "Ambulatory",
      },
      {
        id: 5183,
        experience: "any",
        location: "Warrensville Heights, OH",
        name: "2738 ICU",
        facility: "South Pointe Hospital",
        specialty: "Surgery",
      },
      {
        id: 5184,
        experience: "experienced",
        location: "Warrensville Heights, OH",
        name: "2071 Surgery Center",
        facility: "South Pointe Hospital",
        specialty: "Post-Surgical",
      },
      {
        id: 5185,
        experience: "experienced",
        location: "Warrensville Heights, OH",
        name: "3762 Wound Care Center",
        facility: "South Pointe Hospital",
        specialty: "ICU",
      },
      {
        id: 5186,
        experience: "any",
        location: "Cleveland, OH",
        name: "1442 OR Neuro Inst",
        facility: "Cleveland Clinic Main Campus",
        specialty: "Surgery",
      },
      {
        id: 5187,
        experience: "any",
        location: "Cleveland, OH",
        name: "2757 OR Arterial Vascular",
        facility: "Cleveland Clinic Main Campus",
        specialty: "Surgery",
      },
      {
        id: 5188,
        experience: "any",
        location: "Cleveland, OH",
        name: "2764 OR General",
        facility: "Cleveland Clinic Main Campus",
        specialty: "Surgery",
      },
      {
        id: 5189,
        experience: "any",
        location: "Cleveland, OH",
        name: "2768 OR Orthopaedic",
        facility: "Cleveland Clinic Main Campus",
        specialty: "Orthopaedics",
      },
      {
        id: 5190,
        experience: "experienced",
        location: "Cleveland, OH",
        name: "2783 PACU-Adult",
        facility: "Cleveland Clinic Main Campus",
        specialty: "Post-Surgical",
      },
      {
        id: 5191,
        experience: "any",
        location: "Cleveland, OH",
        name: "2774 OR Urology",
        facility: "Cleveland Clinic Main Campus",
        specialty: "Surgery",
      },
      {
        id: 5192,
        experience: "experienced",
        location: "Lorain, OH",
        name: "1037 Lorain AMB Surg Ctr",
        facility: "Lorain FHC Surgery",
        specialty: "Surgery",
      },
      {
        id: 5193,
        experience: "any",
        location: "Akron, OH",
        name: "9457 9100 Neuroscience Unit",
        facility: "Akron General Medical Center",
        specialty: "Neuro Med Surg",
      },
      {
        id: 5194,
        experience: "experienced",
        location: "Vero Beach, FL",
        name: "6005 NURSE-ADMIN.WOMEN'S HEALTH",
        facility: "Cleveland Clinic Indian River",
        specialty: "Women's Health",
      },
      {
        id: 5195,
        experience: "any",
        location: "Canton, OH",
        name: "1693 Rehabilitation Unit 4B Rehab",
        facility: "Cleveland Clinic Mercy Hospital",
        specialty: "Sub-Acute & Rehab",
      },
      {
        id: 5196,
        experience: "any",
        location: "Dover, OH",
        name: "6380-Med/Surg ICU Stepdown",
        facility: "Cleveland Clinic Union Hospital",
        specialty: "Telemetry",
      },
      {
        id: 5197,
        experience: "any",
        location: "Euclid, OH",
        name: "2733 ICU",
        facility: "Euclid Hospital",
        specialty: "ICU",
      },
      {
        id: 5249,
        experience: "experienced",
        location: "Stuart, FL",
        name: "7035 MMC Surgery Center",
        facility: "Cleveland Clinic Martin Health",
        specialty: "Surgery",
      },
    ],
  },
  {
    id: 504,
    heading: "Medium Schedule Flexibility / Low Patient Acuity",
    schedule: "medium",
    acuity: "low",
    idealCandidate: [
      "Collaborative with other departments",
      "Able to identify and resolve patient care issues",
      "Self-reliant",
      "High degree of expertise in given area",
      "Very focused in delivery and communication of information to patients/family",
    ],
    units: [
      {
        id: 5198,
        experience: "any",
        location: "Avon, OH",
        name: "4686 NRS-4 Observation",
        facility: "Avon Hospital",
        specialty: "Observation",
      },
      {
        id: 5199,
        experience: "experienced",
        location: "Akron, OH",
        name: "9511 Endoscopy",
        facility: "Akron General Medical Center",
        specialty: "Digestive Disease",
      },
      {
        id: 5200,
        experience: "any",
        location: "West Palm Beach, FL",
        name: "2726 WPB Nursing Administration",
        facility: "CC Florida West Palm Beach",
        specialty: "Ambulatory",
      },
      {
        id: 5201,
        experience: "any",
        location: "Canton, OH",
        name: "1613 Medical and Surgical Unit 5B",
        facility: "Cleveland Clinic Mercy Hospital",
        specialty: "Orthopaedics",
      },
      {
        id: 5202,
        experience: "any",
        location: "Canton, OH",
        name: "1778 Ambulatory Care Clinic",
        facility: "Cleveland Clinic Mercy Hospital",
        specialty: "Ambulatory",
      },
      {
        id: 5203,
        experience: "experienced",
        location: "Dover, OH",
        name: "6460- OB",
        facility: "Cleveland Clinic Union Hospital",
        specialty: "Other non-ICU area",
      },
      {
        id: 5204,
        experience: "experienced",
        location: "Dover, OH",
        name: "6920- Wound Care",
        facility: "Cleveland Clinic Union Hospital",
        specialty: "Surgery",
      },
      {
        id: 5205,
        experience: "experienced",
        location: "Cleveland, OH",
        name: "1817 Center Family Medicine",
        facility: "Fairview Hospital",
        specialty: "Other non-ICU area",
      },
      {
        id: 5206,
        experience: "any",
        location: "Cleveland, OH",
        name: "2431 Urology",
        facility: "Cleveland Clinic Main Campus",
        specialty: ["Ambulatory", "Renal"],
      },
      {
        id: 5207,
        experience: "any",
        location: "Mayfeild Heights, OH",
        name: "3061 Family Maternity Center",
        facility: "Hillcrest Hospital",
        specialty: "Women's Health",
      },
      {
        id: 5208,
        experience: "experienced",
        location: "Cleveland, OH",
        name: "2230 Cardiac Prep Recovery",
        facility: "Cleveland Clinic Main Campus",
        specialty: "Cardiac",
      },
      {
        id: 5209,
        experience: "experienced",
        location: "Cleveland, OH",
        name: "2152 Peds Gastroenterology Hepatology Nutrition",
        facility: "Cleveland Clinic Main Campus",
        specialty: "Surgery",
      },
      {
        id: 5210,
        experience: "any",
        location: "Beachwood, OH",
        name: "1550 Beachwood Admin",
        facility: "Beachwood Fam Hlth Surgery Ctr",
        specialty: "Ambulatory",
      },
      {
        id: 5211,
        experience: "any",
        location: "Lorain, OH",
        name: "1562 Admin-Ely-Chestnut Comm",
        facility: "Lorain FHC Surgery",
        specialty: "Ambulatory",
      },
      {
        id: 5212,
        experience: "any",
        location: "Wooster, OH",
        name: "1558 Wooster FHC Admin",
        facility: "Wooster Family Health Center",
        specialty: "Ambulatory",
      },
      {
        id: 5213,
        experience: "experienced",
        location: "Strongsville, OH",
        name: "1039 Strongsville ASC Admin",
        facility: "Strongsville Fam Hlth Surg Ctr",
        specialty: "Surgery",
      },
      {
        id: 5214,
        experience: "any",
        location: "Stuart, FL",
        name: "Float Pool",
        facility: "Cleveland Clinic Martin Health",
        specialty: "Ambulatory",
      },
      {
        id: 5215,
        experience: "any",
        location: "Vero Beach, FL",
        name: "6206 OUTPATIENT ENDOSCOPY",
        facility: "Cleveland Clinic Indian River",
        specialty: "Digestive Disease",
      },
      {
        id: 5216,
        experience: "any",
        location: "Stuart, FL",
        name: "6015 CRP Staffing Resource Admin",
        facility: "Cleveland Clinic Martin Health",
        specialty: "Travel and Float",
      },
      {
        id: 5217,
        experience: "any",
        location: "Garfield Heights, OH",
        name: "2829 3 South/4 South",
        facility: "Marymount Hospital",
        specialty: "Medical Telemetry",
      },
      {
        id: 5218,
        experience: "experienced",
        location: "solon",
        name: "2076 Clinic Nursing",
        facility: "Solon Call Ctr Access to Care",
        specialty: "Other non-ICU area",
      },
      {
        id: 5219,
        experience: "experienced",
        location: "solon",
        name: "1551 Solon Administration",
        facility: "Solon Family Health Center",
        specialty: "Other non-ICU area",
      },
      {
        id: 5220,
        experience: "any",
        location: "Independence, OH",
        name: "1547 Ind Administration",
        facility: "Independence Family Hlth Ctr",
        specialty: "Ambulatory",
      },
    ],
  },
  {
    id: 505,
    heading: "Low Schedule Flexibility / High Patient Acuity",
    schedule: "low",
    acuity: "high",
    idealCandidate: [
      "Excellent interpersonal skills",
      "High clinical skills",
      "Very organized",
      "Independent worker",
      "Can easily prioritize",
      "Calm in any situation",
      "Compassionate",
      "Understanding",
    ],
    units: [
      {
        id: 5221,
        experience: "any",
        location: "Akron, OH",
        name: "9455 7100 Medical Unit",
        facility: "Akron General Medical Center",
        specialty: "Cardiac",
      },
      {
        id: 5222,
        experience: "any",
        location: "Akron, OH",
        name: "9451 5200B Orthopedic Unit",
        facility: "Akron General Medical Center",
        specialty: "Orthopaedics",
      },
      {
        id: 5223,
        experience: "any",
        location: "Akron, OH",
        name: "9512 Epilepsy Monitoring Unit",
        facility: "Akron General Medical Center",
        specialty: "Neuro Med/Surg",
      },
      {
        id: 5223,
        experience: "experienced",
        location: "Akron, OH",
        name: "9507 Emergency Department",
        facility: "Health and Wellness Bath",
        specialty: "Emergency",
      },
      {
        id: 5224,
        experience: "any",
        location: "Dover, OH",
        name: "6780- ED",
        facility: "Cleveland Clinic Union Hospital",
        specialty: "Emergency",
      },
      {
        id: 5225,
        experience: "any",
        location: "Cleveland, OH",
        name: "2817 Parkview Terrace",
        facility: "Fairview Hospital",
        specialty: "Neuro Med/Surg",
      },
      {
        id: 5226,
        experience: "any",
        location: "Cleveland, OH",
        name: "1896 Pediatric Ed",
        facility: "Fairview Hospital",
        specialty: "Peds Emergency",
      },
      {
        id: 5227,
        experience: "any",
        location: "Cleveland, OH",
        name: "2788 Clin Decision Unit 5 South",
        facility: "Fairview Hospital",
        specialty: "Observation",
      },
      {
        id: 5228,
        experience: "any",
        location: "Cleveland, OH",
        name: "2720 Nursing Administration",
        facility: "Lutheran Hospital",
        specialty: "Medical Telemetry",
      },
      {
        id: 5229,
        experience: "any",
        location: "Garfield Heights, OH",
        name: "2825 2 East/2 South",
        facility: "Marymount Hospital",
        specialty: "Post-Surgical",
      },
      {
        id: 5230,
        experience: "any",
        location: "Cleveland, OH",
        name: "2082 H51- Colorectal Surgery",
        facility: "Cleveland Clinic Main Campus",
        specialty: "Digestive Disease",
      },
      {
        id: 5231,
        experience: "any",
        location: "Cleveland, OH",
        name: "2814 H50 Colorectal Surgery",
        facility: "Cleveland Clinic Main Campus",
        specialty: "Digestive Disease",
      },
      {
        id: 5233,
        experience: "any",
        location: "Cleveland, OH",
        name: "2232 J8-3 Cardiac Vip/1921 Suites",
        facility: "Cleveland Clinic Main Campus",
        specialty: "Medical Telemetry",
      },
      {
        id: 5234,
        experience: "any",
        location: "Cleveland, OH",
        name: "2759 OR Cardiac",
        facility: "Cleveland Clinic Main Campus",
        specialty: "Surgery",
      },
      {
        id: 5234,
        experience: "experienced",
        location: "Cleveland, OH",
        name: "2880 Taussig Administration",
        facility: "Cleveland Clinic Main Campus",
        specialty: "Hematology Oncology",
      },
      {
        id: 5235,
        experience: "any",
        location: "Akron, OH",
        name: "9452 5400 Oncology Unit",
        facility: "Akron General Medical Center",
        specialty: "Hematology Oncology",
      },
      {
        id: 5236,
        experience: "any",
        location: "Akron, OH",
        name: "9456 8100 Family Practice Unit",
        facility: "Akron General Medical Center",
        specialty: "Stroke",
      },
      {
        id: 5237,
        experience: "any",
        location: "Cleveland, OH",
        name: "1970 ADRC",
        facility: "Lutheran Hospital",
        specialty: "Behavioral Health",
      },
    ],
  },
  {
    id: 506,
    heading: "Low Schedule Flexibility / Low Patient Acuity",
    schedule: "low",
    acuity: "low",
    idealCandidate: [
      "Great communicator",
      "Works well with others",
      "Very organized",
      "Attention to detail",
      "Critical thinker",
      "Very autonomous",
    ],
    units: [
      {
        id: 5238,
        experience: "experienced",
        location: "port st. lucie",
        name: "7015 CRP Pre Admit",
        facility: "Cleveland Clinic Martin Health",
        specialty: "Surgery",
      },
      {
        id: 5239,
        experience: "experienced",
        location: "Euclid, OH",
        name: "2041 Surgery Center",
        facility: "Euclid Hospital",
        specialty: "Post-Surgical",
      },
      {
        id: 5240,
        experience: "experienced",
        location: "Euclid, OH",
        name: "2040 Surgery",
        facility: "Euclid Hospital",
        specialty: "Surgery",
      },
      {
        id: 5241,
        experience: "any",
        location: "Cleveland, OH",
        name: "3134 4 North Postpartum",
        facility: "Fairview Hospital",
        specialty: "Women's Health",
      },
      {
        id: 5242,
        experience: "experienced",
        location: "Cleveland, OH",
        name: "1544 Head Neck Surgery",
        facility: "Cleveland Clinic Main Campus",
        specialty: "Ambulatory",
      },
      {
        id: 5243,
        experience: "experienced",
        location: "Akron, OH",
        name: "3394 Pulmonary Medicine",
        facility: "Akron Medical Office",
        specialty: "Respiratory Stepdown",
      },
      {
        id: 5244,
        experience: "any",
        location: "Akron, OH",
        name: "9442 2400/3100 Obstetrics Unit",
        facility: "Akron General Medical Center",
        specialty: "Women's Health",
      },
      {
        id: 5245,
        experience: "any",
        location: "Avon, OH",
        name: "1549 Avon Administration",
        facility: "The Richard Jacobs Hlth Ctr",
        specialty: "Ambulatory",
      },
      {
        id: 5246,
        experience: "any",
        location: "Sheffield, OH",
        name: "4476 CC SMG Sheffield",
        facility: "Sheffield Family Health Center",
        specialty: "Ambulatory",
      },
      {
        id: 5247,
        experience: "experienced",
        location: "Twinsburg, OH",
        name: "1560 Twinsburg Administration",
        facility: "Twinsburg Family Hlth Ctr",
        specialty: "Ambulatory",
      },
      {
        id: 5248,
        experience: "any",
        location: "Warrensville Heights, OH",
        name: "2849 Med/Surg 3Rd Floor",
        facility: "South Pointe Hospital",
        specialty: "Medical Telemetry",
      },
    ],
  },
];

/*
Location?????
*/
