import React from "react";
import "./CloseTheCall.css";
import CloseTheCallTabs from "../CloseTheCallTabs/CloseTheCallTabs";
import { CloseTheCallTabsType } from "../../models/CloseTheCallTabsType";

const CloseTheCall = () => {
  const closeTabs: CloseTheCallTabsType[] = [
    {
      id: 201,
      title: "The Assumptive Close",
      overview:
        "Where the recruiter assumes that the candidate is ready to interview or will accept the offer.",
      firstSectionTitle: "When to Use the Assumptive Close",
      firstSectionInfo:
        "Use the Assumptive Close when the candidate has checked most of the boxes in each step of the recruiting process but has not yet confirmed they will interview or accept the offer.",
      secondSectionTitle: "How to Use the Assumptive Close",
      secondSectionInfo:
        "When using the Assumptive Close, you shouldn’t use subjective words like if, were, would, and could. Instead, use active words like when, where, and will.",
      thirdSectionTitle: "What It Sounds Like",
      thirdSectionInfo: [
        { id: 601, content: "When would you be able to start?" },
        {
          id: 602,
          content:
            "What was your favorite part of the interview with the manager?",
        },
        {
          id: 603,
          content:
            "Will you be taking advantage of our reimbursement benefit for [mention specific certifications]",
        },
        {
          id: 604,
          content: "How would you feel if an offer were made to you today?",
        },
        {
          id: 605,
          content: "What would it take to get you to accept our offer?",
        },
        { id: 606, content: "Are you ready to leave your current employer?" },
      ],
    },
    {
      id: 202,
      title: "The Summary Close",
      overview:
        "Selectively summarizing the highlights and helping them visualize what saying yes would mean.",
      firstSectionTitle: "When to Use the Summary Close",
      firstSectionInfo:
        "Use the Summary Close when the candidate needs to see how to connect the dots between what Cleveland Clinic offers and what they want.",
      secondSectionTitle: "How to Use the Summary Close",
      secondSectionInfo:
        "When using the Summary Close, logically connect what they will get to what they want. Remember – you should be connecting them to what matters most to them.",
      thirdSectionTitle: "What It Sounds Like",
      thirdSectionInfo: `"When you come on board, given you are recently out of school and have passed your boards, you will participate in our nurse residency program. All nurse caregivers who have been out of school less than 12 months are required to participate in the residency program, which will include about six hours of training per month on such topics as decision-making, professionalism, patient outcomes, safety, and leadership. This program really sets you up for success and is designed to help ease you into the healthcare environment."`,
    },
    {
      id: 203,
      title: "The Objection Close",
      overview: "Asking for and addressing any objections the candidate has.",
      firstSectionTitle: "When to Use the Objection Close",
      firstSectionInfo:
        "Use the Objection Close once you have made sure that your candidate has understood everything about the role and what it has to offer.",
      secondSectionTitle: "How to Use the Objection Close",
      secondSectionInfo:
        "After the candidate knows what’s on offer, ask for any objections they might have with the role or unit. It allows the candidate to raise any final objections or doubts that they need to clear up without saying no.",
      thirdSectionTitle: "What It Sounds Like",
      thirdSectionInfo: [
        {
          id: 607,
          content: "Is there any reason this shift won’t work for you?",
        },
        {
          id: 608,
          content:
            "What happens to your career goals if you don’t take the position now?",
        },
        {
          id: 609,
          content:
            "Is there anything about the [shift, unit, timing, hospital, position] that is concerning you?",
        },
      ],
    },
    {
      id: 204,
      title: "The Ben Franklin Close",
      overview: "Listing the pros and cons of the decision.",
      firstSectionTitle: "When to Use the Ben Franklin Close",
      firstSectionInfo:
        "Use the Ben Franklin Close when the candidate is hesitant about the decision or an aspect of the offer.",
      secondSectionTitle: "How to Use the Ben Franklin Close",
      secondSectionInfo:
        "Either you or the candidate lists the pros and cons of the unit, role, etc. By seeing how the pros outweigh the cons, the odds of them saying yes to the offer improves. As always, focus on helping them think about the pros and cons of the things that matter most to them.",

      thirdSectionTitle: "What It Sounds Like",
      thirdSectionInfo:
        "Let’s talk about the pros and cons of [the shift, role, people, commute benefits, etc.]. ",
    },
    {
      id: 205,
      title: "The Need Close",
      overview: "Clearly articulate how Cleveland Clinic meets their needs.",
      firstSectionTitle: "When to Use the Need Close",
      firstSectionInfo:
        "Use the Need Close when the candidate is having trouble seeing any distinct benefit to joining Cleveland Clinic or how it is different.",
      secondSectionTitle: "How to Use the Need Close",
      secondSectionInfo:
        "List the things the candidate said they needed from the role you are offering. Then review that list against Cleveland Clinic’s benefits. This could include culture, support, professional development opportunities, etc. Again – always connect back to what matters most to the candidate.",
      thirdSectionTitle: "What It Sounds Like",
      thirdSectionInfo:
        "“You mentioned in our last call how important it is to you to be able to grow in your profession. When you come on board, you will have the opportunity to take part in the department’s residency program that includes leadership development. Beyond that, you can take advantage of our reimbursement program to get certified, which will help you throughout your career at Cleveland Clinic.”",
    },
    {
      id: 206,
      title: "The Scale Close",
      overview: "Get an idea of how interested the candidate is.",
      firstSectionTitle: "When to Use the Scale Close",
      firstSectionInfo:
        "Use the Scale Close if you are unsure of where a candidate stands and want an idea of how interested they are in Cleveland Clinic and/or the role.",
      secondSectionTitle: "How to Use the Scale Close",
      secondSectionInfo:
        "Ask the candidate on a scale of 1-5 where they are on anything you’re unsure about.",
      thirdSectionTitle: "What It Sounds Like",
      thirdSectionInfo: [
        {
          id: 610,
          content:
            "On a scale of 1-5, how does our role compare to your current role?",
        },
        {
          id: 611,
          content:
            "On a scale of 1-5, how does our [specific benefit] stand out as something that could make a different in your career?",
        },
        {
          id: 612,
          content:
            "On a scale of 1 to 5, how important to you are the learning and development opportunities available through our Mandel Global Leadership and Learning Institute?",
        },
        {
          id: 613,
          content:
            "On a scale from 1-5, how important to you is it to work for a leading healthcare system like Cleveland Clinic?",
        },
      ],
    },
    {
      id: 207,
      title: "The Empathy Close",
      overview:
        "Understanding and empathizing with the current emotional state of the candidate",
      firstSectionTitle: "When to Use the Empathy Close",
      firstSectionInfo:
        "Use the Empathy Close when the candidate tells you that they aren’t ready to decide or make a change. It gives them more time to think instead of pushing them for a decision.",
      secondSectionTitle: "How to Use the Empathy Close",
      secondSectionInfo:
        "Using your active listening skills, ask questions to learn more about where your candidate stands and express empathy for where they are. By empathizing with your candidate, you can build a truly transformational relationship with them that can be useful in the long run.",
      thirdSectionTitle: "What It Sounds Like",
      thirdSectionInfo: `Take me through your process on how you are going to make this decision to join the Cleveland Clinic and how you’re feeling about it.`,
      thirdSectionMoreInfo: `Talk me through who you’re going to talk to about changing jobs. What do you think they will say about coming here to Cleveland Clinic? What do you think about that?`,
    },
    {
      id: 208,
      title: "The Testimonial Close",
      overview:
        "Sharing the experiences of actual candidate you have hired who have benefited from joining Cleveland Clinic.",
      firstSectionTitle: "When to Use the Testimonial Close",
      firstSectionInfo:
        "Use the Testimonial Close when the candidate is having concerns or doubts about joining Cleveland Clinic.",
      secondSectionTitle: "How to Use the Testimonial Close",
      secondSectionInfo:
        "Instead of taking about the Clinic, talk about the success stories you know about or offer to connect the candidate with someone who has been successful at Cleveland Clinic.",
      thirdSectionTitle: "What It Sounds Like",
      thirdSectionInfo: [
        {
          id: 614,
          content:
            "Would it help you if I arranged a call for you to speak to a recent hire that wanted the same unit you expressed interest in and you heard first-hand how that caregiver’s experience is going?",
        },
        {
          id: 615,
          content:
            "I’d really like for you to speak to our manager in the XX Unit, as she can tell you firsthand all the great skills you will gain from this unit, which can eventually take you to other specialty areas that you might not even realize.",
        },
        {
          id: 616,
          content:
            "Mary graduated from the same Nursing Program as you, and she started out on our XX Unit. Through her time here at Cleveland Clinic she has worked in 4 units. Can I set some time up for her to speak to you about her experience?",
        },
      ],
    },
  ];

  return (
    <div>
      <div className="close-the-call-container">
        <div className="close-the-call-heading-container">
          <div className="close-the-call-text-container">
            <h1>Close the Call</h1>
            <p>
              Closings are an opportunity to (1) show the next steps and (2)
              reflect on what was effective in reaching the candidate.
            </p>
          </div>
          <div className="after-closing-container">
            <h2>After Closing</h2>
            <ul>
              <li>Schedule interviews</li>
              <li>
                Follow up with candidate and set expectations about timing
              </li>
              <li>Revise templates</li>
            </ul>
          </div>
        </div>
        <div className="closings-container">
          <h2>Closings</h2>
          {closeTabs.map((t) => {
            return <CloseTheCallTabs tabs={t} key={t.id} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default CloseTheCall;
