import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import "./ImportantTopicSelector.css";
import { ValueSelector } from "../../../models/ValueSelector";

type Props = {
  classValue?: string;
  listValue: ValueSelector[];
  stateValue: ValueSelector | undefined | null;
  handleSetValue: (id: number, category: string) => void;
  filterTabs: (level: string) => void;
};

const FlexibilitySelector = ({
  classValue,
  listValue,
  stateValue,
  handleSetValue,
  filterTabs,
}: Props) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = () => {
    if (showDropdown) setShowDropdown(false);
    else setShowDropdown(true);
  };
  const handleClick = (id: number, category: string, level: string) => {
    handleSetValue(id, category);
    filterTabs(level);
    toggleDropdown();
  };
  const ref = useRef<HTMLDivElement>(null);
  const handleOutsideClick = ({ target }: MouseEvent): void => {
    if (ref && ref.current && !ref.current.contains(target as Node)) {
      setShowDropdown(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);
  return (
    <div
      className={`${classValue ? classValue : null} important-select-component`}
      ref={ref}
    >
      <div className="important-select-list-label" onClick={toggleDropdown}>
        <p>
          {stateValue ? stateValue.label : "Select Schedule Flexibility"}
          <FontAwesomeIcon icon={faChevronDown} className="fa-chevron-down" />
        </p>
      </div>
      <div
        className={`important-select-list-container ${
          showDropdown ? null : `hide-dropdown`
        }`}
      >
        {listValue
          ? listValue.map((value: ValueSelector) => (
              <div
                key={value.id}
                className={`important-each-select-list ${
                  stateValue && stateValue.id === value.id
                    ? `important-selected-list-item`
                    : null
                }`}
                onClick={() =>
                  handleClick(value.id, value.category, value.level)
                }
              >
                <p>{value.label}</p>
              </div>
            ))
          : null}
      </div>
    </div>
  );
};
export default FlexibilitySelector;
