import React, { useState } from "react";
import "./GetToYes.css";
import FlexibilitySelector from "./Selectors/FlexibilitySelector";
import AcuitySelector from "./Selectors/AcuitySelector";
import GetToYesTabs from "./GetToYesTabs/GetToYesTabs";
import { GetToYesTabsType } from "../../models/GetToYesTabs";
import personaTemplate from "../../../assets/Persona-Templates.pptx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPerson,
  faCircleInfo,
  faLampStreet,
} from "@fortawesome/pro-regular-svg-icons";
import { ValueSelector } from "../../models/ValueSelector";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { GetToYesData } from "../../data/GetToYesData";

const GetToYes = () => {
  const importantValue: ValueSelector[] = [
    { id: 1001, category: "important", level: "teamwork", label: "Teamwork" },
    { id: 1004, category: "important", level: "training", label: "Training" },
  ];
  const flexibilityValue: ValueSelector[] = [
    { id: 1006, category: "flexibility", level: "high", label: "High" },
    {
      id: 1007,
      category: "flexibility",
      level: "medium",
      label: "Medium",
    },
    {
      id: 1008,
      category: "flexibility",
      level: "low",
      label: "Low",
    },
  ];
  const acuityValue: ValueSelector[] = [
    {
      id: 1009,
      category: "acuity",
      level: "high",
      label: "High",
    },
    {
      id: 1010,
      category: "acuity",
      level: "low",
      label: "Low",
    },
  ];

  const getToYesTabs = GetToYesData;

  const [important, setImportant] = useState<ValueSelector | null | undefined>(
    null
  );
  const [flexibility, setFlexibility] = useState<
    ValueSelector | null | undefined
  >(null);
  const [acuity, setAcuity] = useState<ValueSelector | null | undefined>(null);
  const [tabs, setTabs] = useState<GetToYesTabsType[]>(getToYesTabs);
  const [keyword, setKeyword] = useState<
    string | number | readonly string[] | undefined
  >("");

  const handleSetValue = (id: number, category: string) => {
    try {
      if (category === "important") {
        const targetValue: ValueSelector | undefined = importantValue.find(
          (target) => target.id === id
        );
        setImportant(targetValue);
      } else if (category === "flexibility") {
        const targetValue = flexibilityValue.find((target) => target.id === id);
        setFlexibility(targetValue);
      } else if (category === "acuity") {
        const targetValue = acuityValue.find((target) => target.id === id);
        setAcuity(targetValue);
      }
    } catch (e) {
      if (typeof e === "string") {
        console.error(e);
      } else if (e instanceof Error) {
        console.error(e.message);
      }
    }
  };
  // const filterTabsByTopic = (topic: string) => {
  //   let filteredTabs: GetToYesTabsNew[] = getToYesTabs.filter(
  //     (tab) => tab.section.toLocaleLowerCase() === topic.toLocaleLowerCase()
  //   );
  //   if (flexibility) {
  //     filteredTabs = filteredTabs.filter(
  //       (tab) =>
  //         tab.schedule.toLocaleLowerCase() ===
  //         flexibility.level.toLocaleLowerCase()
  //     );
  //   }
  //   if (acuity) {
  //     filteredTabs = filteredTabs.filter(
  //       (tab) =>
  //         tab.acuity.toLocaleLowerCase() === acuity.level.toLocaleLowerCase()
  //     );
  //   }
  //   setTabs(filteredTabs);
  // };
  const filterTabsByFlex = (flex: string) => {
    let filteredTabs: GetToYesTabsType[] = getToYesTabs.filter(
      (tab) => tab.schedule.toLocaleLowerCase() === flex.toLocaleLowerCase()
    );
    if (important) {
      filteredTabs = filteredTabs.filter(
        (tab) =>
          tab.section.toLocaleLowerCase() ===
          important.level.toLocaleLowerCase()
      );
    }
    if (acuity) {
      filteredTabs = filteredTabs.filter(
        (tab) =>
          tab.acuity.toLocaleLowerCase() === acuity.level.toLocaleLowerCase()
      );
    }

    setTabs(filteredTabs);
  };
  const filterTabsByAcuity = (acuity: string) => {
    let filteredTabs: GetToYesTabsType[] = getToYesTabs.filter(
      (tab) => tab.acuity.toLocaleLowerCase() === acuity.toLocaleLowerCase()
    );
    if (important) {
      filteredTabs = filteredTabs.filter(
        (tab) =>
          tab.section.toLocaleLowerCase() ===
          important.level.toLocaleLowerCase()
      );
    }
    if (flexibility) {
      filteredTabs = filteredTabs.filter(
        (tab) =>
          tab.schedule.toLocaleLowerCase() ===
          flexibility.level.toLocaleLowerCase()
      );
    }
    setTabs(filteredTabs);
  };

  const clearFilters = () => {
    setImportant(null);
    setFlexibility(null);
    setAcuity(null);
    setTabs(getToYesTabs);
    setKeyword("");
  };

  return (
    <div className="gty-container">
      <h1>Get to Yes with Key Talking Points</h1>
      <div className="gty-text-container">
        <p>
          What do you say to a candidate who hesitates to say yes to Cleveland
          Clinic?
        </p>
        <p>
          Deliver compelling reasons to say yes based on what you know is most
          important to them.
        </p>
        <p>
          If your candidate has questions about the amazing benefits we offer,
          please click{" "}
          <a
            href="https://jobs.clevelandclinic.org/benefits-2/"
            target="_blank"
            rel="noreferrer"
          >
            here.
          </a>
        </p>
      </div>
      <div className="talking-points-container">
        <div className="talking-points-text-container">
          <h2>Talking Points</h2>
          <p>Find what to say based on what you know about the candidate:</p>
        </div>
        <div className="gty-main-content-container">
          <div className="gty-left-nav-container">
            {/* <div className="gty-challenge-container">
              <p className="the-challenge-header">
                <FontAwesomeIcon
                  className="gty-fa-person"
                  icon={faMountain as Icon}
                />
                The Question
              </p>
              <p>
                Because we want to set our candidates up for success, click
                below to see how nurse managers and nurse caregivers talk about
                their units.
              </p> 
               <ImportantTopicSelector
                classValue={"gty"}
                listValue={importantValue}
                stateValue={important}
                handleSetValue={handleSetValue}
                filterTabs={filterTabsByTopic}
              /> 
             </div> */}
            <div className="gty-candidate-container">
              <p className="the-candidate-header">
                <FontAwesomeIcon
                  icon={faPerson as IconProp}
                  className="gty-fa-person"
                />
                The Unit
              </p>
              <p>
                Because we want to set our candidates up for success, click
                below to see how nurse managers and nurse caregivers talk about
                their units.
              </p>
              <div className="gty-making-assumptions-container">
                <FontAwesomeIcon
                  icon={faCircleInfo as IconProp}
                  className="gty-making-assumptions-icon"
                />
                <p>Making assumptions?</p>
                <p>
                  Refer to a{" "}
                  <a href={personaTemplate} download>
                    Persona
                  </a>{" "}
                  template!
                </p>
              </div>
              <p>Their desired schedule flexibility is</p>
              <FlexibilitySelector
                classValue={"gty"}
                listValue={flexibilityValue}
                stateValue={flexibility}
                handleSetValue={handleSetValue}
                filterTabs={filterTabsByFlex}
              />
              <p>The patient acuity that best matches their Persona is</p>
              <AcuitySelector
                classValue={"gty"}
                listValue={acuityValue}
                stateValue={acuity}
                handleSetValue={handleSetValue}
                filterTabs={filterTabsByAcuity}
              />
              <p className="search-header">
                Search for a keyword within the quotes:
              </p>
              <input
                spellCheck="true"
                type="text"
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
              />
            </div>
            <div
              className={`clear-filters-box
              ${
                important !== null ||
                flexibility !== null ||
                acuity !== null ||
                keyword !== ""
                  ? `clear-filter-enabled`
                  : null
              }
              `}
            >
              <p onClick={clearFilters}>
                <FontAwesomeIcon
                  icon={faLampStreet as IconProp}
                  className="fa-lamp"
                />{" "}
                Clear Filters
              </p>
            </div>
          </div>
          <div className="gty-topics-tabs">
            <div className="gty-no-example-units-container">
              <p>No content available. Please make another selection.</p>
            </div>
            {tabs.length > 0 ? (
              <>
                {tabs.map((tab, i) => (
                  <div className="get-to-yes-tabs-container" key={i}>
                    <GetToYesTabs tab={tab} keyword={keyword} />
                  </div>
                ))}
              </>
            ) : (
              <div className="no-gty-topics-tabs">
                <h3 className="no-each-gty-topics-tabs">
                  There is no content that meets the selected criteria. Please
                  make another selection.
                </h3>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default GetToYes;
