import React from "react";
import "./KnowTheCandidate.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPerson,
  faPalette,
  faPeoplePants,
  faMountain,
  faCircleInfo,
  faUpRightFromSquare,
} from "@fortawesome/pro-regular-svg-icons";
import { CandidateContent } from "../../models/CandidateContent";
import { QuestionsContent } from "../../models/QuestionsContent";
import personaTemplate from "../../../assets/Persona-Templates.pptx";
import storytellingTemplate from "../../../assets/Storytelling-Exercise-TemplatesREV2.pptx";
import taskMapTemplate from "../../../assets/Task-Map-Templates.pptx";
import empathyTemplate from "../../../assets/Empathy-Templates.pptx";

const KnowTheCandidate = () => {
  const theCandidateContent: CandidateContent[] = [
    {
      id: 6,
      title: "The Context",
      description:
        "A.k.a. the candidate's backstory - what's the key info to know?",
      linkTitle: "Persona",
      linkUrl: personaTemplate,
      info: "Use this to break down what the backstory is into Motivations, Frustrations, and Biography.",
      backgroundColor: "#E4F0F8",
      icon: faPalette,
    },
    {
      id: 7,
      title: "The Characters",
      description:
        "A.k.a. the influencers - who is pushing or pulling the candidate to/from nursing, CC, etc.?",
      linkTitle: "Empathy Map",
      linkUrl: empathyTemplate,
      info: "Use this to identify who is influencing how the candidate thinks, speaks, and sees.",
      backgroundColor: "#EBF2EA",
      icon: faPeoplePants,
    },
    {
      id: 8,
      title: "The Challenge",
      description:
        "A.k.a. the big barrier - what problem is holding the candidate back from considering a Cleveland Clinic job?",
      linkTitle: "Task Map",
      linkUrl: taskMapTemplate,
      info: `Use this to determine how the candidate gets to yes. What “tasks” must happen for them to accept a role at Cleveland Clinic?`,
      backgroundColor: "#EEEEED",
      icon: faMountain,
    },
  ];

  const probingQuestionsContent: QuestionsContent[] = [
    {
      id: 9,
      title: "PROBING FOR THE CONTEXT",
      questions: [
        "What made you want to become a nurse?",
        "What do you want to achieve as a nurse?",
        "What was your favorite thing about nursing school or the last place you worked? The least?",
      ],
      backgroundColor: "#E4F0F8",
    },
    {
      id: 10,
      title: "PROBING FOR THE CHARACTERS",
      questions: [
        "What kind of helpful advice and information are you finding during your job search?",
      ],
      backgroundColor: "#EBF2EA",
    },
    {
      id: 11,
      title: "PROBING FOR THE CHALLENGE",
      questions: [
        "What other jobs or hospitals are you looking at? Why?",
        "What makes you interested in this position?",
      ],
      backgroundColor: "#EEEEED",
    },
  ];

  return (
    <div className="know-the-candidate-container">
      <h1>Know the Candidate</h1>

      <div className="know-the-candidate-text-container">
        <p>
          These three sections are crucial for understanding the candidate. Use
          them to capture the candidate’s context, influencing characters, and
          major challenge, so that you can connect with them on how Cleveland
          Clinic can be the hero.
        </p>
      </div>
      <div className="the-candidate-container">
        <div className="the-candidate-header-container">
          <FontAwesomeIcon icon={faPerson} className="fa-person" />
          <h2>The Candidate</h2>
        </div>
        <p className="the-candidate-container-header-info">
          One way to dig deeper with a candidate is to understand their story
          using these three steps:
        </p>
        <div className="context-characters-challenge-container">
          {theCandidateContent.map((content) => (
            <div
              style={{ backgroundColor: `${content.backgroundColor}` }}
              className="each-content-container"
              key={content.id}
            >
              <div className="each-content-header-container">
                <FontAwesomeIcon icon={content.icon} className="fa-icons" />
                <h3>{content.title}</h3>
              </div>
              <p className="content-description">{content.description}</p>
              <button>
                <a href={content.linkUrl} download>
                  <FontAwesomeIcon
                    icon={faUpRightFromSquare}
                    className="fa-link-icon"
                  />
                  {content.linkTitle}
                </a>
              </button>
              <FontAwesomeIcon icon={faCircleInfo} className="fa-circle-info" />
              <p className="content-info">{content.info}</p>
            </div>
          ))}
        </div>
        <div className="storytelling-steps">
          <p>
            These storytelling steps are crucial for understanding the candidate
            in the overall storytelling flow:
          </p>
          <button>
            <a href={storytellingTemplate} target="_blank" rel="noreferrer">
              <FontAwesomeIcon
                icon={faUpRightFromSquare}
                className="fa-link-icon"
              />
              Storytelling Exercise
            </a>
          </button>
          <div className="storytelling-steps-info">
            <FontAwesomeIcon icon={faCircleInfo} className="fa-circle-icon" />
            <p>
              Use this to connect the Context, Characters, and Challenge to how
              Cleveland Clinic can play the Hero in the candidate’s experience.
            </p>
          </div>
        </div>
      </div>
      <h2>Level 2 Listening Beyond the Phone Screen</h2>
      <p className="beyond-the-phone-screen-text-container">
        What more do you need to know about the candidate? Incorporate the
        sample questions below, where appropriate into your phone screen and
        conversations with candidates:{" "}
      </p>
      <div>
        <h3>Other Probing Questions</h3>
        <div className="probing-for-context-characters-challenge-container">
          {probingQuestionsContent.map((question) => (
            <div
              style={{ backgroundColor: `${question.backgroundColor}` }}
              key={question.id}
              className="each-question-container"
            >
              <h4>{question.title}</h4>
              <ul>
                {question.questions.map((q, index) => (
                  <li key={index}>{q}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        <div className="additional-questions">
          <h4>ADDITIONAL QUESTIONS TO ASK (1-2):</h4>
          <ul>
            <li>Why did you decide to become a nurse</li>
            <li>What do you want to accomplish in your nursing career?</li>
            <li>What was your favorite part about nursing school?</li>
            <li>What other hospitals are you looking at and why?</li>
            <li>
              What makes you excited about becoming a caregiver at Cleveland
              Clinic?
            </li>
            <li>
              What is or who is influencing your decision to explore this role?
            </li>
            <li>
              What websites, tools, and contacts have you found most valuable
              during your job search?
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default KnowTheCandidate;
