import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-regular-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

type Props = {
  isAuth: () => void;
  createClaim: () => void;
  setIsLoggedIn: (arg0: boolean) => void;
  isLoggedIn: boolean;
};
const TestSSO = ({ isAuth, createClaim, isLoggedIn, setIsLoggedIn }: Props) => {
  return (
    <div className="login-screen">
      <div>
        <FontAwesomeIcon
          className="loading-logo"
          icon={faSpinner as IconProp}
        />
      </div>
      <p>isLoggedIn is {isLoggedIn.toString()}</p>
      <div>
        <button>
          <a href="https://ccnurseunitfinderapi.aspirant.com/Auth/Login/?returnUrl=https%3A%2F%2Fccstory.aspirant.com%2F">
            Login
          </a>
        </button>
        <button>
          <a href="https://localhost:7210/Auth/Login?returnUrl=https%3A%2F%2Flocalhost%3A3000%2F">
            Local Login
          </a>
        </button>
        {/* <button onClick={authRoute}>Auth</button> */}
        <button onClick={isAuth}>Is Auth?</button>
        <button onClick={createClaim}>Create Claim</button>

        <button onClick={() => setIsLoggedIn(true)}>Go to Project</button>
      </div>
    </div>
  );
};

export default TestSSO;
