// export interface GetToYesTabsNew {
//   id: number;
//   heading: string;
//   section: string;
//   schedule: string;
//   acuity: string;
//   bulletPoints?: {
//     id: number;
//     point: string;
//     quoteType: string;
//   }[];
// }

export const GetToYesData = [
  {
    id: 2001,
    heading: "High Schedule Flexibility / High Patient Acuity",
    section: "Teamwork",
    schedule: "High",
    acuity: "High",
    bulletPoints: [
      {
        id: 3001,
        point: "Close team members, great work mentality.",
        quoteType: "NC",
      },
      {
        id: 3002,
        point:
          "Excellent teamwork and commitment to safe quality patient care.",
        quoteType: "NC",
      },
      {
        id: 3003,
        point:
          "Excellent teamwork, charge nurses are intelligent, helpful, and are always willing to teach.",
        quoteType: "NC",
      },
      {
        id: 3004,
        point: "Great team work and morale.",
        quoteType: "NC",
      },
      {
        id: 3005,
        point: "Great teamwork and leadership.",
        quoteType: "NC",
      },
      {
        id: 3006,
        point: "Great teamwork with staff, nurses, etc.",
        quoteType: "NC",
      },
      {
        id: 3007,
        point:
          "Having great teamwork. Always working on goals and improvements. Usually achieving/going above hospital and national goals.",
        quoteType: "NC",
      },
      {
        id: 3008,
        point: "Lots of support and teamwork.",
        quoteType: "NC",
      },
      {
        id: 3009,
        point:
          "Our day shift is amazing. Teamwork with coworkers unlike anywhere else I've worked.",
        quoteType: "NC",
      },
      {
        id: 3010,
        point:
          "Our team is incredible. We work well together to provide the best patient care possible. Our unit is smaller so we are very close and care about our coworkers' well being.",
        quoteType: "NC",
      },
      {
        id: 3011,
        point:
          "Our unit works very well together,  great teamwork and our staff will pick up to help out with down time.  We are not only co-workers, but, family also.",
        quoteType: "NC",
      },
      {
        id: 3012,
        point: "Teamwork and people with positive energy.",
        quoteType: "NC",
      },
      {
        id: 3013,
        point:
          "Teamwork, great Assistant Nurse Manager and Nurse Manager who help out when needed.",
        quoteType: "NC",
      },
      {
        id: 3014,
        point: "Teamwork is exceptional.",
        quoteType: "NC",
      },
      {
        id: 3015,
        point: "The teamwork and support for new graduate nurses.",
        quoteType: "NC",
      },
      {
        id: 3016,
        point: "The teamwork and trust that we have in each other.",
        quoteType: "NC",
      },
      {
        id: 3017,
        point:
          "The teamwork not just between other nurses but with the staff, fellows, residents, Clinical Therapists, Health Unit Coordinators and Radition Therapist. Everyone listens to each other and has good teamwork.",
        quoteType: "NC",
      },
      {
        id: 3018,
        point:
          "The teamwork on this unit enhances staff relationships and improves overall morale.",
        quoteType: "NC",
      },
      {
        id: 3019,
        point:
          "The teamwork on this unit enhances staff relationships and improves overall morale.",
        quoteType: "NC",
      },
      {
        id: 3020,
        point: "The teamwork, and the autonomy we have as nurses.",
        quoteType: "NC",
      },
      {
        id: 3021,
        point:
          "This unit is unique in so many ways. The teamwork is phenomenal. You will NEVER be left to fend for yourself and everyone is always there if you have any questions. We work right with the physicians and they are always willing to jump in when it is needed as well.",
        quoteType: "NC",
      },
      {
        id: 3022,
        point:
          "Unit driven on teamwork, strong communication between nurses and providers",
        quoteType: "NC",
      },
      {
        id: 3139,
        point:
          "Our caregivers interact with patients  as a team to try to solve the patient's personal problems, change in behaviors, or try to better understand their world and themselves. We use evidence-based modalities, including cognitive behavioral therapy (CBT), dialectical behavior therapy (DBT), art, recreational and music therapies.",
        quoteType: "NM",
      },
      {
        id: 3140,
        point:
          "Teamwork is essential in this unit to ensure the safety of all patients and caregivers.",
        quoteType: "NM",
      },
      {
        id: 3141,
        point:
          "The relationship between nursing staff and providers is especially strong.  Each nurse knows that, if they are overwhelmed or one-on-one with a critical patient, the rest of the team has their back and won't let them fall behind.  The sense of ownership the team has is exceptional, and there is a pride in being part of this team that contributes to excellent care for patients and a sense of belonging for caregivers.",
        quoteType: "NM",
      },
      {
        id: 3142,
        point:
          'High acuity for a "step down."  Emotional support frequently needed with patients and families because of diagnosis complexity.  Assessments occurring every 15 minutes to every two hours.  Bedside procedures, lines, and drips.',
        quoteType: "NM",
      },
      {
        id: 3143,
        point:
          "It is a small family oriented department.  The administrator, nurse manager, department chair and front end manager all work very closely together to figure out our patient and employee needs.",
        quoteType: "NM",
      },
      {
        id: 3144,
        point:
          "My team supports each other every shift, every day. They throw showers and parties for each other to celebrate the milestones in life that matter to each other (bridal, baby, birthday, holiday, etc.). They pick up shifts for each other, knowing they will not get additional incentives, to ensure that each caregiver is getting their much needed time away.  They answer each others call lights, pass meds to help each other catch up, stay late to help the oncoming shift, toilet each other's patients, pick up shifts when a unit is down, etc.  No one says \"that's not my patient\" and it shows.  On a recent patient experience survey a patient made a comment that she could not remember who her nurse was because every caregiver on the unit took care of her like they were her nurse. The caregivers on this unit enjoy seeing each other succeed professionally as well. They send each other caregiver celebrations regularly and at huddles and end of the week updates we celebrate accomplishments and praise caregivers for a job well done.  We highlight when someone is mentioned in a survey by name, when a RN gets certified or ACLS, when someone graduates school, wins an award, etc. This team of caregivers is unique because they genuinely care for each other like a family and I couldn't imagine going through the last two years with any other team of people.",
        quoteType: "NM",
      },
      {
        id: 3145,
        point:
          "My unit prides themselves on how they educate their patients. They are very thorough with post surgical patients. We are currently doing a project with breast population to provide a lanyard for Jackson Pratt (JP) drains for home so they do not hang when patient is showering and changing. Teamwork is huge. Everyone is always helping each other with admissions and discharges. We have a high turn over so all hands on deck all day and night.",
        quoteType: "NM",
      },
      {
        id: 3146,
        point:
          "On our unit, we have a nurse practitioner on duty 24/7. We also do rounds every morning with the interdisciplinary team. These rounds are led by nurses and we discuss the plan of care together.",
        quoteType: "NM",
      },
      {
        id: 3147,
        point:
          "Our teamwork approach - our willingness to adapt and the staff being so flexible.",
        quoteType: "NM",
      },
      {
        id: 3148,
        point:
          "Our unit is unique in having many seasoned nurses that have been with us a long while. We recently have onboarded some newer nurses that are blending well with our team. We are small but mighty, and they always have each others back.",
        quoteType: "NM",
      },
      {
        id: 3149,
        point:
          "Teamwork and the vast acuity of patients.  We have short stay cardiac procedure patients as well as fresh open hearts and then Mobile Intensive Care Unit (MICU) patients.",
        quoteType: "NM",
      },
      {
        id: 3150,
        point: "Teamwork.  Family feel.  Desire and willingness to teach.",
        quoteType: "NM",
      },
      {
        id: 3151,
        point:
          "The amount of teamwork we display.  We make sure everyone feels welcome and we make sure any question is ok to ask.",
        quoteType: "NM",
      },
      {
        id: 3152,
        point:
          "The nursing staff works in 5 different modalities. It's something different each day and most enjoy the change of environment and pace each area offers. We also are the vascular access team for the hospital and nurses love that aspect of the job. They place PICC's and midlines at the bedside along with ultrasound guided PIV's.",
        quoteType: "NM",
      },
      {
        id: 3153,
        point:
          "The teamwork, laughter, and working relationships among the staff and other disciplines.",
        quoteType: "NM",
      },
      {
        id: 3154,
        point:
          "We are high volume, high acuity and also serve a very elderly population.  Our nurses and providers work as a great team providing excellent care to our patients.",
        quoteType: "NM",
      },
      {
        id: 3155,
        point:
          "We are the only unit in the hospital that has the orthopedic population.  We work closely with surgery and we are also the unit that takes all the surgical patients that are admitted for extended recovery, observation and surgeries to come in.  We work well together as a team and our team is like a family atmosphere.",
        quoteType: "NM",
      },
      {
        id: 3156,
        point:
          "We have an excellent team who work extremely hard and are passionate about their jobs.",
        quoteType: "NM",
      },
      {
        id: 3156,
        point:
          "We have an incredibly strong team consisting of very experienced and new grads nurses. My experienced nurses work incredibly hard every day to mentor and coach our new nurses.",
        quoteType: "NM",
      },
    ],
  },
  {
    id: 2002,
    heading: "Medium Schedule Flexibility / High Patient Acuity",
    section: "Teamwork",
    schedule: "Medium",
    acuity: "High",
    bulletPoints: [
      {
        id: 3023,
        point:
          "We come from all different backgrounds and years of experience and are able to work well together as a team.",
        quoteType: "NC",
      },
      {
        id: 3024,
        point: "We have an amazing leader and a fabulous team.",
        quoteType: "NC",
      },
      {
        id: 3025,
        point:
          "We have excellent teamwork, if we didn't have that, we wouldn't be able to get our duties done or even be able to take a break or have lunch.",
        quoteType: "NC",
      },
      {
        id: 3026,
        point:
          "We have great teamwork and nurses that have been here along time.",
        quoteType: "NC",
      },
      {
        id: 3027,
        point:
          "We never know what is coming through the door, and our teamwork and communication is essential to our patient care. The majority of the physician team treat nurses as peers; asking us for help instead of demanding it. Our nurses, respiratory therapists and paramedics work side by side to efficiently carry out the orders that are placed.",
        quoteType: "NC",
      },
      {
        id: 3028,
        point:
          "We provide care throughout the entire main campus with wound, ostomy and continence care. We are unique in this way that we collaborate and work with many of the different teams at the main campus.",
        quoteType: "NC",
      },
      {
        id: 3029,
        point: "We work well together.",
        quoteType: "NC",
      },
      {
        id: 3030,
        point:
          "I work in radiology department, we have good teamwork together.",
        quoteType: "NC",
      },
      {
        id: 3031,
        point:
          'Lots of autonomy when caring for critically ill patients with very little outside "help". Great relationships with co-workers.',
        quoteType: "NC",
      },
      {
        id: 3032,
        point:
          "Our teamwork and care for our patient and the quality of care we provide.",
        quoteType: "NC",
      },
      {
        id: 3033,
        point:
          "Our unit is unique in a sense that we provide care to those who have just endured a surgical procedure. Our goal is to education and rehabilitate.",
        quoteType: "NC",
      },
      {
        id: 3034,
        point:
          "Patients have many needs, multiple comorbidities and social issues. Not a lot of technical skills required, but cannot be effective without psychosocial skills.",
        quoteType: "NC",
      },
      {
        id: 3035,
        point:
          "Providing all around great care and treatment is #1 goal in patient care to ensure proper healing process.",
        quoteType: "NC",
      },
      {
        id: 3036,
        point:
          "Teamwork and really caring for patient and family in plan of care.",
        quoteType: "NC",
      },
      {
        id: 3037,
        point:
          "The amount of time that we spend not only on patient care but on family education regarding patient care.Teamwork and really caring for patient and family in plan of care.",
        quoteType: "NC",
      },
      {
        id: 3038,
        point:
          "The cardiovascular ICU is very unique that we deal with very critical patients, but they are typically short stay.  Nurse practitioners play a huge role in our care.",
        quoteType: "NC",
      },
      {
        id: 3039,
        point: "The kindness of the doctors and nurses.",
        quoteType: "NC",
      },
      {
        id: 3040,
        point:
          "The operating room essentially consists of multiple disciplines coming together to perform a procedure safely and effectively. Without one discipline (anesthesia, surgeon, nursing) there would be no way to perform a surgery safely.",
        quoteType: "NC",
      },
      {
        id: 3041,
        point:
          "The patient has a different experience here then anywhere else because the patient is usually asleep or high sedated-takes a great deal of trust. They depend on us be the eyes, ears, and voice for their care.",
        quoteType: "NC",
      },
      {
        id: 3042,
        point:
          "There is little patient interaction other than prior to  bringing your patient back and being with them until they are inducted. this is the time you have to make that great first impression and relieve any fears or concerns they may have.",
        quoteType: "NC",
      },
      {
        id: 3043,
        point:
          "This unit is unique because of all of the different types of patients that we have. I feel as though I am always learning something new.",
        quoteType: "NC",
      },
      {
        id: 3044,
        point:
          "We actually have a few minutes to interact with the patient and their families doing the pre-surgery checklist and procedures.",
        quoteType: "NC",
      },
      {
        id: 3045,
        point:
          "We are a family, here for each other and our patients day in and out,  we are efficient and get the cases done using the most updated and accurate plan of care for each of our patients.",
        quoteType: "NC",
      },
      {
        id: 3046,
        point:
          "We are a procedure area.  Patients spend just a short time here so we have to take every opportunity that we can to connect with our patients.",
        quoteType: "NC",
      },
      {
        id: 3047,
        point:
          "We are a small NICU but have a high acuity with many surgical cases.  We work great together and pick up where there is a need to support another.",
        quoteType: "NC",
      },
      {
        id: 3048,
        point:
          "We are a specialized unit and see our patients frequently, a lot of them feel like we are their family.",
        quoteType: "NC",
      },
      {
        id: 3049,
        point:
          "We are all together in a large room, so you never feel alone with a sick infant.  Most co-workers jump in and help without needing to be asked. Its a great team. Including Neonatal Nurse Practitioners and physicians.",
        quoteType: "NC",
      },
      {
        id: 3050,
        point:
          "We are an operating room. I get about 5 minutes pre-op with my patient to introduce myself to them and possibly a family member who is with them. In those 5 minutes I have to establish a sense of trust due to the fact this person in the next 15-20 minutes is going to trust me with their life!  So this in itself makes us unique because we take care of one person at a time.",
        quoteType: "NC",
      },
      {
        id: 3051,
        point:
          "We are an outpatient endoscopy facility compromised of RNs and techs who do colonoscopy's and Esophagogastroduodenoscopy (EGDs).  We all know each others roles are  able to jump in and help out whenever needed.",
        quoteType: "NC",
      },
      {
        id: 3052,
        point:
          "We are essential, because the majority of people may have to have a surgical procedure sometime in their life.",
        quoteType: "NC",
      },
      {
        id: 3053,
        point:
          "We are highly efficient, its often said, a well oiled machine.  Every staff member knows their role to safely, efficiently and compassionately care for our patients in a timely manner.",
        quoteType: "NC",
      },
      {
        id: 3054,
        point:
          "We are outpatient and are able to spend the needed time with our patients to reinforce teaching and insure patients understand what is happening.",
        quoteType: "NC",
      },
      {
        id: 3055,
        point:
          "We generally have 2 patients at a time so are better able to give individualized care and meet their needs.",
        quoteType: "NC",
      },
      {
        id: 3056,
        point:
          "We like to call it the 3rd floor family, because we all work very closely together to help each other out and provide exceptional care to our patients.",
        quoteType: "NC",
      },
      {
        id: 3057,
        point:
          "We take care of all ages (0-82, y.o.), we take care of patients with illnesses/surgeries of every body system, we take care of parents/families/patients that are anxious, scared, grieving, angry and upset and we need to spend a lot of time to explain/educate patients/families re: their illness.",
        quoteType: "NC",
      },
      {
        id: 3058,
        point: "We work with one patient at a time.",
        quoteType: "NC",
      },
      {
        id: 3157,
        point:
          "We care for very complex medical cardiology patients of high acuity. We often receive patients from nearby hospitals who are not as equipped as we are to handle certain patient cases. Most importantly, the staff truly care for one another and it shows through their teamwork.",
        quoteType: "NM",
      },
      {
        id: 3158,
        point:
          "Ambulatory environments offer nurses autonomy and variety of service lines and ways to interact with patients - different than traditional bedside care.",
        quoteType: "NM",
      },
      {
        id: 3159,
        point:
          "Being smaller the staff is all very close.  They are also very bonded as they all know the unique sadness experienced in oncology nursing.  The patient rooms on our unit are also bigger than other units rooms making patient care much easier to perform.  We also get a lot of repeat patients so the staff forms relationships with the patients.",
        quoteType: "NM",
      },
      {
        id: 3160,
        point:
          "Close knit cohesive group who has a proven track record of providing care for very ill patients outside of the ICU setting.  Some of the patient population that we care for will only receive care on our unit because of their specialized needs, E.g. Endobronchial valves, home ventilators, Bipap, CADD pumps, high flow oxygen.  In addition, we provide care for higher acuity medical patients with need for pulmonary hygiene.  We have dedicated respiratory therapists on the unit who we work very closely with to meet the needs of our patients.",
        quoteType: "NM",
      },
      {
        id: 3161,
        point: "Complex cardiac devices, overall intensity of patients.",
        quoteType: "NM",
      },
      {
        id: 3162,
        point: "Culture of team promotes teamwork and mentoring.",
        quoteType: "NM",
      },
      {
        id: 3163,
        point:
          "Everyone has a voice and is expected to contribute to making the peds infusion center the best place for care and the best place to work. Leaders and staff work together on a regular basis to solve problems and continually evaluate outcomes. Our CI board, visual management, and consistent review of the unit's OKRs have made this a great place to work and the best place for patients to receive  pediatric subspecialty infusion care anywhere. We value all disciplines and the contribution of each individual on the team. We have established two resiliency/wellness chairs that have partnered with the nurse manager to create a culture that regularly includes wellness and resiliency activities every two weeks. This has been very well received by staff.",
        quoteType: "NM",
      },
      {
        id: 3164,
        point:
          "Large unit, but small family feel. Teamwork approach. Management team present on all shifts.",
        quoteType: "NM",
      },
      {
        id: 3165,
        point:
          "Main Campus offers highly complex patient populations. Within Med/Surg, we receive a dynamic patient population which offers experienced and new graduate nurses variety and growth within their profession. Our shifts are rarely monotonous and something is learned each day. I started my career in med/surg and I have stayed within the institute my entire 9 years within the Clinic and I have fully enjoyed learning and growing within this scope.",
        quoteType: "NM",
      },
      {
        id: 3166,
        point:
          "My Unit is unique due to the acuity of the patients and the teamwork that occurs within it. The teamwork is incredible and the experienced staff wants to help the newer staff grow and learn to become the best nurse possible.",
        quoteType: "NM",
      },
      {
        id: 3167,
        point:
          "Only unit to care for post-op Cardiovascular patients out of the CVICU.",
        quoteType: "NM",
      },
      {
        id: 3168,
        point:
          "Our department manages patients from the OR every day and we work very well as a team to support each other and our patients.",
        quoteType: "NM",
      },
      {
        id: 3169,
        point:
          "Our nursing team has the unique opportunity of seeing our patients from diagnosis to (in some cases) remission if their cancer. We get to know our patients and celebrate the little wins and big victories. We also band together when patients decline instead of improve; we work together through the more emotionally challenging moments on our unit so that as a team, we can offer our patients and their families all of the support the need. Our physicians, pharmacists and APPs view the RNs as a critical part of the team and advocate for us, educate us, and listen to our concerns regarding patients or how the floor operates.",
        quoteType: "NM",
      },
      {
        id: 3170,
        point:
          "Periop units are unique in and of themselves.  We are highly skilled and high performing.  We provide service for multiple specialties, but are small enough to have developed family-like relationships with our doctors and each other.",
        quoteType: "NM",
      },
      {
        id: 3171,
        point:
          "Perioperative services has PSU unit which is a different kind of nursing & stress. Patients are stable and RNs make sure everything is ready and safe for surgery.",
        quoteType: "NM",
      },
      {
        id: 3172,
        point:
          "PACU is post op Phase 1&2 level of care and it is challenging having critical care patients and outpatients together. Also staggered shifts are unique.",
        quoteType: "NM",
      },
      {
        id: 3173,
        point:
          "We are a cardiac medical unit.  Our staff become very familiar with this specialty, however, they still learn about and manage many other diagnosis.  Our unit takes pride in the teamwork they demonstrate each and every day.  On our unit you can grow your practice in a supportive learning environment.   We appreciate experienced nurses and new graduate nurses as we all work together to meet our patients needs.",
        quoteType: "NM",
      },
      {
        id: 3174,
        point:
          "Surgery is unique in that we care for one patient at a time.  You have very limited opportunity to impress upon your patients and families that they will be safe and cared for with the utmost dignity and respect and treated like a family member.  It is an honor and privilege to take care of a loved one when they are at their most vulnerable.  It can be fast paced and physically demanding, but extremely rewarding.",
        quoteType: "NM",
      },
      {
        id: 3175,
        point: "Teamwork, unity, diversity.",
        quoteType: "NM",
      },
      {
        id: 3176,
        point:
          "The amazing team work, highly skilled team members and inter-departmental relationships.",
        quoteType: "NM",
      },
      {
        id: 3177,
        point:
          "The goal is the same, help our cancer patients, the engagement to find a better way to do things, and the patient themselves, everyone understands how important they are to the patient and their journey.",
        quoteType: "NM",
      },
      {
        id: 3178,
        point:
          "The group is hard working and provide quality care to the surgical patient, endoscopy, and cardiac cath lab. We are unique as we have procedural caregivers and bedside caregivers who cross train to accommodate the patient needs. We also cover off shift needs with call hours for all 3 areas.",
        quoteType: "NM",
      },
      {
        id: 3179,
        point:
          "The patient populations that we see make our unit unique. We see ENT and Plastic surgery patients, cardiology patients, and high acuity medicine patients, which makes our nurses extremely well rounded nurses and prepared to go on and do whatever they want to do next. Also, I truly think the amount of teamwork we display on the unit is hard to come by nowadays and an amazing thing to be apart of.",
        quoteType: "NM",
      },
      {
        id: 3180,
        point:
          "The Rehab patient remains on the unit for a longer period of time which allows a relationship to be built between the caregivers and the patient.",
        quoteType: "NM",
      },
      {
        id: 3181,
        point:
          "The team comes to not only serve their patients but each other.  True family and eager to teach and precept new nurses to understand critical concepts and medications.  RN and surgeon direct communications and plan of care visits put the unit as one of the highest in the hospital.  Most nurses within this unit will state they love what they do.  Patient is in the unit start to finish with their hospitalization due to our universal bed concept.  We recover post-operatively and patient with go through levels of care right within the same unit as well as discharged from the unit.  Builds trust and bonds with patients and families.",
        quoteType: "NM",
      },
      {
        id: 3182,
        point:
          "The team work & support my nurses, PCNAs provide to one another.",
        quoteType: "NM",
      },
      {
        id: 3183,
        point:
          "The unit patient population is the surgical neonate requiring collaboration with many disciplines. The care we provide optimizes outcomes while providing the tools for the families to care for their neonate at home.",
        quoteType: "NM",
      },
      {
        id: 3184,
        point:
          "The culture on the unit is very focused on collaboration and communication. Each nurse has very high standards for themselves and the unit.",
        quoteType: "NM",
      },
      {
        id: 3185,
        point:
          "This department has a strong sense of family and teamwork that bands together to support each other and our patients.",
        quoteType: "NM",
      },
      {
        id: 3186,
        point:
          "We are a behavioral health unit that also specializes in medical co-morbidities.  There are patients on the unit that require psychiatric stability while we manage medical problems that may arise.  We can start IVs, draw blood, manage PEG tubes.  We get that experience on the unit more than an adult behavioral health unit would. Can keep up medical skills.",
        quoteType: "NM",
      },
      {
        id: 3187,
        point:
          "We are a fast paced unit with a wide variety of patients. We get the opportunity to see patients before cardiac surgery, and we also get to see them once they transition out of ICU. We are fortunate to care for these patients until they are successfully discharged home after major surgery. Our team has a great emphasis on patient education, early mobility, lifestyle modifications, and community follow up. We work as a team to provide the best possible care to our patients.",
        quoteType: "NM",
      },
      {
        id: 3188,
        point:
          "We are a high functioning team who is pleasant and accomplished achieving the safety award the last 2 years. We blend multiple service lines on this unit all while also being the only oncology floor of the facility.",
        quoteType: "NM",
      },
      {
        id: 3189,
        point:
          "We aren't just a unit, we are family. I have never worked on a unit that was such a strong team and constant advocates for patient safety.",
        quoteType: "NM",
      },
      {
        id: 3190,
        point:
          "We get a good balance with our patients, we get to work closely with many specialties, we get to carry on a normal work schedule with being home on the weekends, every day is a new day and new experiences.",
        quoteType: "NM",
      },
      {
        id: 3191,
        point: "We have great team work and the best ANMs in all of the world.",
        quoteType: "NM",
      },
      {
        id: 3192,
        point:
          "We have tremendous teamwork within nursing, but also with our APPs. We work very closely with our NPs/PAs Mon-Fri to improve patient care.",
        quoteType: "NM",
      },
      {
        id: 3193,
        point:
          "We are a very close group.  It is important we provide the best possible care to our patients but also take care of each other.",
        quoteType: "NM",
      },
      {
        id: 3194,
        point:
          "We work so well together with the docs, the NNPs, the RTs, the support staff from Children's, and each other.  We embody the teamwork value.  Our patients are small but fierce and we spend a lot of time working with the families, teaching them how to care for their babies and themselves in their new role of caretaker of a NICU graduate.",
        quoteType: "NM",
      },
      {
        id: 3195,
        point:
          "Working in the operating room we have an impact on our patents for the rest of their lives. The trauma patent comes to us broken and we fix them, the cancer patient comes and we remove the tumor, etc. The operating room is also unique in the fact that we have one patient and a team of caregivers providing care to that patient.",
        quoteType: "NM",
      },
    ],
  },
  {
    id: 2007,
    heading: "Low Schedule Flexibility / High Patient Acuity",
    section: "Teamwork",
    schedule: "Low",
    acuity: "High",
    bulletPoints: [
      {
        id: 3196,
        point:
          "Free standing Emergency Department, limited resources, esp. respiratory and pharmacy.  The team of caregivers work very well together with the providers.",
        quoteType: "NM",
      },
      {
        id: 3197,
        point:
          "We utilize physician assistants which allows RN caregivers to collaborate and communicate regarding the patients plan of care. We also offer an ostomy class to new ostomy patients that provides education to patients and their families regarding the care of an ostomy. An active shared governance.  We have historically had four assistant nurse managers in place at all times, which provides leadership on most shifts. ",
        quoteType: "NM",
      },
      {
        id: 3198,
        point:
          "It is the only Alcohol and Drug Recovery unit in our organization. We not only have patients from all around the country, but we also serve our own in the event they need help. We take great pride in our patient privacy and the care we provide. As a manager I am very lucky to have staff who genuinely want to care for this population and do so with a compassionate and understanding view.",
        quoteType: "NM",
      },
      {
        id: 3199,
        point:
          "Observation patients require a lot of communication and collaboration within the team, and this team rounds several times a day.",
        quoteType: "NM",
      },
      {
        id: 3200,
        point:
          "Our patients range from routine surgeries (knee/hip/shoulders/etc..) to acute traumatic fractures. Our fragility hip fractures have a high incidence of delirium and require critical thinking.",
        quoteType: "NM",
      },
      {
        id: 3201,
        point:
          "Our unit utilizes PAs that are commonly on the unit to discuss patient concerns.  We also have ostomy class 2 times per week for patients and their family members.  This class is very well received by all including our nursing students on the floor.  Historically, we have had leadership available on most shifts with myself and 3-4 ANMs available for assistance.",
        quoteType: "NM",
      },
      {
        id: 3202,
        point:
          "We are a specialty unit, no one can float into our Infusion center because of the complexity and knowledge a nurse has to have to give treatments all day long.",
        quoteType: "NM",
      },
      {
        id: 3203,
        point:
          "We have been the number one heart center for 27 years straight. We care for people all over the world who have been turned down elsewhere to be a surgical candidate. Those patients coma here and walk out. It is pretty amazing.",
        quoteType: "NM",
      },
      {
        id: 3203,
        point:
          "We see very low acuity patients all the way up to full arrests making us very unique. No day is ever the same because you never know what will walk through the doors. Our staffing and schedules are very different from inpatient units as well.",
        quoteType: "NM",
      },
      //BEGIN NC FROM TRAINING SECTION
      {
        id: 3127,
        point:
          "It is the only one in all of Cleveland Clinic that is dedicated to detoxing patients.",
        quoteType: "NC",
      },
      {
        id: 3128,
        point:
          "My unit is unique because surgery is a different kind of nursing. It's something you don't really learn in nursing school. It's 4-5 different teams focusing on one patient and ensuring they have a smooth surgery versus 4-5 different teams focusing on 4 plus patients at a time.",
        quoteType: "NC",
      },
      {
        id: 3129,
        point:
          "Only unit that takes ventilator dependent patients, special certifications are required (NIHSS, epilepsy).",
        quoteType: "NC",
      },
      {
        id: 3130,
        point: "Only unit with vent patients, frequent stroke/trach patients.",
        quoteType: "NC",
      },
      {
        id: 3131,
        point:
          "We are a very specialized surgical unit caring for patients with colorectal cancers and other digestive disease issues. Our patients require a lot of follow up care and teaching about new stomas etc as well as education, medications etc.",
        quoteType: "NC",
      },
      {
        id: 3132,
        point: "We are specialized in comfort care for our patients.",
        quoteType: "NC",
      },
      {
        id: 3133,
        point:
          "We do procedures that other hospitals do not perform anywhere else in the world.",
        quoteType: "NC",
      },
      {
        id: 3134,
        point:
          "We get patients from all over the country who come her to have colorectal surgery.",
        quoteType: "NC",
      },
      {
        id: 3135,
        point:
          "We have a lot of turn around for patient admits.  We admit and discharge double the patients compared to other units",
        quoteType: "NC",
      },
      {
        id: 3136,
        point:
          "We have multiple experienced nurses who are excellent resources for new RNs. There is a protocol for several of the surgeries that our patients receive so RNs/PCNAs all know what to expect in their care.",
        quoteType: "NC",
      },
      {
        id: 3137,
        point:
          "We have to be stroke certified and we are equipped to take vents amongst other patients.",
        quoteType: "NC",
      },
      {
        id: 3138,
        point:
          "Cardiac surgery runs differently then the other OR specialties due to the volume and complexity of the patients.",
        quoteType: "NC",
      },
      {
        id: 3138,
        point:
          "The constant fluctuation in volume.  No day is ever the same as any other day.  Our volumes and acuities are completely unpredictable and we can never close or go on diversion.  We can never delay accepting a patient for any reason.",
        quoteType: "NC",
      },
      {
        id: 3138,
        point:
          "We are able to care for the children who are sick and educate parents on how to best care for their children after the ER visit.",
        quoteType: "NC",
      },
    ],
  },
  {
    id: 2003,
    heading: "High Schedule Flexibility / Low Patient Acuity",
    section: "Teamwork",
    schedule: "High",
    acuity: "Low",
    bulletPoints: [
      {
        id: 3059,
        point:
          "Comfortable with our providers and managers when I have a question, they are easily approachable and informative.",
        quoteType: "NC",
      },
      {
        id: 3060,
        point:
          "Every unit is unique in the their own ways. What makes Observation unit so unique is the teamwork and the strive for patient care.",
        quoteType: "NC",
      },
      {
        id: 3061,
        point:
          "Everyone pitches in and helps when the situation needs it- everyone is on the same page with the same goals.",
        quoteType: "NC",
      },
      {
        id: 3062,
        point:
          "Great management, open communication, co-workers that care about your personal well-being.",
        quoteType: "NC",
      },
      {
        id: 3063,
        point:
          "Great office staff, like family.  We go above and beyond to make sure that our patients have what they need.",
        quoteType: "NC",
      },
      {
        id: 3064,
        point:
          "Great teamwork for excellent patient care at an ambulatory surgery center. All staff readily share knowledge/expertise/tips for each OR case to run smoothly.",
        quoteType: "NC",
      },
      {
        id: 3065,
        point:
          "I work Ambulatory. My office is very busy, even though there are only 3 providers in the office, it is one of the busiest on the East side. It often feels like a private practice and we strive to make sure that our patients feel as if it is.",
        quoteType: "NC",
      },
      {
        id: 3066,
        point:
          "I work in a role that deals with every unit, rounding the hospital and helping in emergency situations.",
        quoteType: "NC",
      },
      {
        id: 3067,
        point:
          "It is a small ambulatory setting office and we all work well together and cover each other.",
        quoteType: "NC",
      },
      {
        id: 3068,
        point:
          "It's an outpatient medical office, not a unit.  I don't think I've ever worked with such a cohesive crew, though, and morale is wonderfully high.",
        quoteType: "NC",
      },
      {
        id: 3069,
        point:
          "Lactation offers support; we have the opportunity to spend time with patients to educate and assist with breast feeding.",
        quoteType: "NC",
      },
      {
        id: 3070,
        point:
          "Maternal Fetal Medicine is very specialized. We deal with many specific conditions.",
        quoteType: "NC",
      },
      {
        id: 3071,
        point:
          "Multiple specialties within plastic surgery, see a variety of patients daily.",
        quoteType: "NC",
      },
      {
        id: 3072,
        point:
          "My current unit in Pain management is unique because we are a smaller unit which requires us all to use teamwork on the regular. We all get along well, work well together and  seem to treat one another like family.",
        quoteType: "NC",
      },
      {
        id: 3073,
        point:
          "Our nurses do most of our triaging and education via phone and mychart.  We also have the opportunity to talk with patients in person during office visits and during stress testing or echocardiograms done here in the office.  We use every opportunity to review the treatment plan with our patients and answer any questions that may be brought up to the best of our ability and at the top of our license.",
        quoteType: "NC",
      },
      {
        id: 3074,
        point:
          "Right now I work in ambulatory urology and we have exceptional people. The management has done a very good job hiring people who fit in and help.",
        quoteType: "NC",
      },
      {
        id: 3075,
        point: "Small team and all work well together.",
        quoteType: "NC",
      },
      {
        id: 3076,
        point: "Supporting moms and their breast feeding journey.",
        quoteType: "NC",
      },
      {
        id: 3077,
        point:
          "Teamwork. We have a lot of fun together. Whether it is a potluck or decorating the unit for holidays/seasons, we always find a way to make work fun.",
        quoteType: "NC",
      },
      {
        id: 3078,
        point: "Teamwork, ambulatory surgery center.",
        quoteType: "NC",
      },
      {
        id: 3079,
        point:
          "The ebb and flow of patients varies. I often have extra time to do the extras for patients (including education) that many units do not have time for.",
        quoteType: "NC",
      },
      {
        id: 3080,
        point:
          "The speed of patient care and turn over after recovery of patients.",
        quoteType: "NC",
      },
      {
        id: 3081,
        point: "The teamwork and patient focused care is exceptional.",
        quoteType: "NC",
      },
      {
        id: 3082,
        point: "Treating mom and baby during pregnancy.",
        quoteType: "NC",
      },
      {
        id: 3083,
        point:
          "We all work great as a team, everyone gets along well, and we support one another on a daily basis.",
        quoteType: "NC",
      },
      {
        id: 3084,
        point:
          "We are a combined unit with Heart & Vascular patients.  Very diverse in staffing and education levels of employees.",
        quoteType: "NC",
      },
      {
        id: 3085,
        point:
          "We are a group of highly dedicated, motivated, autonomous nurses who seek out opportunities to prevent loss of patient lives.  We focus on supporting the nurses, educating nurses, and quality improvement in Code Blue Teams, Rapid Response Teams, Stroke Teams, STEMIs, and trach care.",
        quoteType: "NC",
      },
      {
        id: 3086,
        point:
          "We are a small unit and we need to each do our portion to provide for each patient.",
        quoteType: "NC",
      },
      {
        id: 3087,
        point:
          " We are a specialized department and the nurses here are required to perform many different duties.",
        quoteType: "NC",
      },
      {
        id: 3088,
        point:
          "We are an ambulatory surgery center that just does pain management procedures and minor surgeries. We often cycle between the different areas assisting areas that are swamped with patients.",
        quoteType: "NC",
      },
      {
        id: 3089,
        point:
          "We are an ambulatory surgical unit, and we begin to assess patient's needs on admission. We verify where home prescriptions are to be e-scripted, involve family in discharge instructions and potential patient needs at home.",
        quoteType: "NC",
      },
      {
        id: 3090,
        point:
          "We are constantly fitting patients in, especially OB's. Where other departments will just schedule at the next appointment. We may need to get the patient in same day even if we do not have any appointments available.",
        quoteType: "NC",
      },
      {
        id: 3091,
        point:
          "We are caring for both ambulatory outpatients and some of the sickest inpatients every day while performing very specialized procedures.",
        quoteType: "NC",
      },
      {
        id: 3092,
        point:
          "We are generally trusted to assess patients' readiness to safety expedite them to the OR. The patients' hospital stay is based on our initial assessment of how they entered the hospital. Excellent accurate documentation is required to obtain the baseline health status so if there is a change we will know the difference.",
        quoteType: "NC",
      },
      {
        id: 3093,
        point:
          "We do nurse visits and have a lot of autonomy working at the highest level of our licenses.",
        quoteType: "NC",
      },
      {
        id: 3094,
        point: "We do teaching before and after procedures.",
        quoteType: "NC",
      },
      {
        id: 3095,
        point: "We do work together well.  We have great camaraderie.",
        quoteType: "NC",
      },
      {
        id: 3096,
        point:
          "We function with more autonomy than a nurse on a unit.  We have an assignment that can be flexed to triage the most important to be seen first and adjust with the mother to be there to observe/assist/teach when the infant is feeding.",
        quoteType: "NC",
      },
      {
        id: 3097,
        point:
          "We have a high volume of patients and our work is specific to the lungs.  Our patient population consists of ICU patients, In-patients and out-patients, providing cancer screening, interventions and lung transplant follow-up.  We have an opportunity to establish long term relationships with our patients that need to have frequent procedures.",
        quoteType: "NC",
      },
      {
        id: 3098,
        point:
          "We preop patients then they are off to surgery, we turn a lot of patients through and don't typically have the same patient throughout our scheduled shift.",
        quoteType: "NC",
      },
      {
        id: 3099,
        point:
          "We prepare all adult outpatients and Target Controlled Infusion (TCI) patients in a timely manner for first round OR cases.",
        quoteType: "NC",
      },
      {
        id: 3100,
        point: "We strive for teamwork.",
        quoteType: "NC",
      },
      {
        id: 3101,
        point:
          "We take care of outpatients and patients that are coming in to be admitted after surgery.",
        quoteType: "NC",
      },
      {
        id: 3102,
        point:
          "We work together and that is the only way we accomplish our patient care goals.",
        quoteType: "NC",
      },
      {
        id: 3204,
        point:
          "As surgical practices, the nurses are flexible with ever-changing schedules. Providers schedules open and close daily and the team must be prepared to handle an influx of patients in a short period of time. Team nursing also sets us apart in the ambulatory setting as the expectations on the team has grown tremendously.",
        quoteType: "NM",
      },
      {
        id: 3205,
        point:
          "Definitely a cultural of caring for not only patients, but caregivers caring/supporting each other.  Fast-paced environment.",
        quoteType: "NM",
      },
      {
        id: 3206,
        point:
          "Each of my outpatient clinics are very unique. They are all different specialties or subspecialties, with different needs.",
        quoteType: "NM",
      },
      {
        id: 3207,
        point:
          "I currently have the best team of nurses in such a way that everyone jumps in at anytime. The are punctual, flexible, dependable, fun, and always willing to learn what GI offers.",
        quoteType: "NM",
      },
      {
        id: 3208,
        point:
          "My team is not a separate unit: serve as part of the professional RN care team as lactation consultant and perinatal educators.  Our work primarily impacts care, outcomes and quality in Obstetrics, Pediatrics, and NICU.  Serve as experts to support care of lactating patients in all areas of the hospital as well as Cleveland Clinic caregivers needing lactation support/services with return to work.",
        quoteType: "NM",
      },
      {
        id: 3209,
        point:
          "Ophthalmology specialty, great teamwork, strong patient experience.",
        quoteType: "NM",
      },
      {
        id: 3210,
        point:
          "Our RNs are used for a lot of teaching in the exam rooms and on the phone.  Our Derm RNs are in the procedures and provide all follow up care and set up the post visits.  Our ortho RNs bring patients in pre and post op do check on status.",
        quoteType: "NM",
      },
      {
        id: 3211,
        point:
          "Our unit is unique as we are support to other nurses at the bedside. The focus is on the health and wellness of mom and baby and ensuring they are off to a good nutritional start prior to discharge. We provide prenatal education to prepare families on what to expect and how to care for themselves and infant. We also provide outpatient resources (ex: support groups and outpatient follow up phone calls and consults). We share safe sleep and maternal post birth warning signs in an effort to decrease infant and maternal mortality. Our team works collaboratively with OB and Peds offices and all areas within Women and Children's Services.",
        quoteType: "NM",
      },
      {
        id: 3212,
        point:
          "Outpatient Pulmonary/Allergy is growing - the department and services continue to increase and the support staff needed to care for patients must grow with it. Several new positions have been added, in work flows that have never been utilized in the department before. We are growing and learning at the same time.",
        quoteType: "NM",
      },
      {
        id: 3213,
        point:
          "The forensic nursing team travel across five counties, 12 hospitals to provide 24/7 on call coverage for victims of sexual and physical assault from birth to end of life.  We spend on average, 4-6 hours with each patient we care for.  Our department is mostly PRN staff, and this is their second job in the system.  They work 36-40 hours in their primary job and then take 32 hours of on call for the forensic nursing in addition to their primary job. Many of our nurses have families, are in school and we work really hard to help support them to have a work life balance.",
        quoteType: "NM",
      },
      {
        id: 3214,
        point:
          "The nursing team likes being here and likes each other.  They have work-life balance by being off on weekends and holidays and being home at night for their families.",
        quoteType: "NM",
      },
      {
        id: 3215,
        point:
          "Every nurse who has taken a pay-cut to work here (some $4/hr.), have never regretted it because they are happy.",
        quoteType: "NM",
      },
      {
        id: 3216,
        point:
          "The specialty is unique.  We have many sub-specialties within the department.  Many patients travel from all over the world to be here.  All of the physicians are surgeons so there can be extensive pre-operative instructions and post-op instructions.  Also patients may not be a candidate for surgery yet and need instruction on what needs to be accomplished first. The procedure itself may need extensive explaining, wound care and dressing changes.  Also many diagnosis may need multiple surgeries so it becomes a staged process.  Many patients are cancer related care where there are multiple questions about approaches, strategies and plans.",
        quoteType: "NM",
      },
      {
        id: 3217,
        point:
          "The type of patients we see are seen frequently giving our team time to become familiar with the special needs of the patient with cancer. I have to say our caregivers are another component that makes our unit unique. Their compassion is amazing and reflects in their work with the patients.",
        quoteType: "NM",
      },
      {
        id: 3218,
        point:
          "We are a small unit where the nursing staff are very good to each other. They make things work.",
        quoteType: "NM",
      },
      {
        id: 3219,
        point:
          "We are a very busy ambulatory office, but our nurses will still utilize basic nursing skills such as assessment, patient education and IV starts for stress testing and soon we will offer nurse visits for Lasix and sub q injections.",
        quoteType: "NM",
      },
      {
        id: 3220,
        point:
          "We are a very quick-paced unit with frequent patient turnover. We provide a significant amount of patient teaching/education when pre-oping and discharging surgical patients. My nurses also get to practice skills such as IV placement, foley catheter insertion, drain management/teaching, etc., which they often enjoy doing to keep their skill set up.",
        quoteType: "NM",
      },
      {
        id: 3221,
        point:
          "We are small and work closely with all departments in our facility.  Patient care is everyone's responsibility and everyone works together regardless of who they report to.",
        quoteType: "NM",
      },
      {
        id: 3222,
        point:
          "We make patients feel at ease when coming in for what some feel is an embarrassing procedure. Patients frequently comment on what a wonderful experience they have had, even though they did not really want to be here. Nursing staff is trained in all roles so they can jump in and help whenever needed.",
        quoteType: "NM",
      },
      {
        id: 3223,
        point:
          "While we do not have a unit with patients, our department supports the nurses and managers who do. Nurses and patients need to have nurses supervisors that they can trust and rely on.",
        quoteType: "NM",
      },
    ],
  },
  {
    id: 2004,
    heading: "Medium Schedule Flexibility / Low Patient Acuity",
    section: "Teamwork",
    schedule: "Medium",
    acuity: "Low",
    bulletPoints: [
      {
        id: 3103,
        point:
          "I can only speak for the night shift crew who helps out each other when we are short staffed. Everyone is very helpful and get along well with others.",
        quoteType: "NC",
      },
      {
        id: 3104,
        point:
          "I work in an ambulatory urology office where the staffing pool is small and we are very much a family.",
        quoteType: "NC",
      },
      {
        id: 3105,
        point:
          "Smaller unit, we work together 4-5 times per week.  Our manager is receptive to our feedback as well as our concerns and makes appropriate changes.  Staff feels acknowledged.",
        quoteType: "NC",
      },
      {
        id: 3106,
        point:
          "We are small and our management LISTENS to our concerns. When hiring, they also try to choose people who are a good fit for the unit and the group we have established.",
        quoteType: "NC",
      },
      {
        id: 3107,
        point:
          "We use a team approach to patient care. Whether prepping a patient or recovering, all available staff members jump in and help as much as they possibly can.",
        quoteType: "NC",
      },
      {
        id: 3108,
        point:
          "We work as a team a lot and our management team is receptive to our concerns, and offers resolution as needed.",
        quoteType: "NC",
      },
      {
        id: 3109,
        point:
          "We work well as a team and get along very well. We help each other without asking. We exceed our expectations. We appreciate our management and Environmental Services workers!",
        quoteType: "NC",
      },
      {
        id: 3110,
        point: "Friendly, funny and extremely helping coworkers.",
        quoteType: "NC",
      },
      {
        id: 3111,
        point:
          "Our unit is unique in that we are only open M-F :6a-6p. this provides a lot of time to have team building activities outside of work. We work extremely well as a unit and it is important to be able to have time outside of work to do fun things together.",
        quoteType: "NC",
      },
      {
        id: 3112,
        point:
          "We have a strong team with pediatric specific experience and expertise. We actually all get along well and work well together.",
        quoteType: "NC",
      },
      {
        id: 3113,
        point:
          "We have amazing teamwork; genuinely like each other and like what we do.",
        quoteType: "NC",
      },
      {
        id: 3114,
        point:
          "We work together.  Communicating patient needs resulting in positive patient outcomes.",
        quoteType: "NC",
      },
      {
        id: 3224,
        point:
          "Ambulatory out patient setting - managing patients for multi-disciplinary appointments, heavy Spanish speaking population requiring advocacy and teamwork.",
        quoteType: "NM",
      },
      {
        id: 3225,
        point:
          "Ambulatory specialties are unique and require some working knowledge of the specialty.",
        quoteType: "NM",
      },
      {
        id: 3226,
        point:
          "It is a procedural unit with a great variety of nursing skills utilized.  We do work very closely together.  Most work is Monday - Friday 8a-5pm, but there is a good amount of evening and weekend call.",
        quoteType: "NM",
      },
      {
        id: 3227,
        point:
          "NOC nurses work remotely from their homes and they deal with patients on a one-on-one basis to meet their needs before moving on to the next patient.",
        quoteType: "NM",
      },
      {
        id: 3228,
        point:
          "The passion and pride of the caregiver's that work here.  The sense of family, always willing to help each other out.",
        quoteType: "NM",
      },
      {
        id: 3229,
        point:
          "Though our procedures and actual time with the patient is short, it is very important to spend to make sure all is covered and the care is very focused and safe.",
        quoteType: "NM",
      },
      {
        id: 3230,
        point:
          "We have a great mix of staff. A lot of different experience whether it's time as an RN or area of experience.  The teamwork is absolutely amazing to watch.",
        quoteType: "NM",
      },
      {
        id: 3231,
        point:
          "We have a nice mix of experienced nurses and new grads that bring a fresh perspective. We also have a lot of nurses that started out as PCNAs on the unit which makes them great assets to us.",
        quoteType: "NM",
      },
      {
        id: 3232,
        point: "We have a very rapid pace and everyone helps each other.",
        quoteType: "NM",
      },
      {
        id: 3233,
        point:
          "We have the privilege to take care of both moms and babies at their most vulnerable time.  We see both healthy and sick patients, but they all benefit from the education that our team works tirelessly to not only provide, but enhance.",
        quoteType: "NM",
      },
      {
        id: 3234,
        point:
          "We provide 1:1 care for patients in active labor. We do not have 24/7 in-house OB providers and anesthesia, but they are on-call and available. Nurses must be able to predict potential outcomes and be more proactive to prevent adverse events. They use autonomy in their care while practicing within their scope. They have to be excellent communicators and are not shy to give feedback and suggestions to physicians and other members of the team.",
        quoteType: "NM",
      },
    ],
  },
  {
    id: 2005,
    heading: "Low Schedule Flexibility / Low Patient Acuity",
    section: "Teamwork",
    schedule: "Low",
    acuity: "Low",
    bulletPoints: [
      {
        id: 3115,
        point:
          "We work with a variety of patients with different head and neck issues, that can effect every day life for them. Eating, talking, drinking and breathing can be affected by an array of different disease and disabilities. As a nurse it is rewarding to be able to follow a patient from the time they are diagnosed, through the surgery process, and through recovery.",
        quoteType: "NC",
      },
      {
        id: 3116,
        point:
          "We say congratulations to all of our patients! I work on Post Partum!",
        quoteType: "NC",
      },
      {
        id: 3117,
        point:
          "We provided care to couplets, so a new mother and baby. This means 2 patients in the room with 2 different provider groups of OB and pediatrics. New mothers are here with a variety of medical issues in addition to having given birth to a baby. The time they are in the hospital is brief and there is a great deal of teaching to cover and tasks to complete in the short time.",
        quoteType: "NC",
      },
      {
        id: 3118,
        point: "We have a lot of seniority on our unit and work as a team.",
        quoteType: "NC",
      },
      {
        id: 3119,
        point:
          "We educate parents whether they are new or experienced, and it is nice to see the confidence and happiness when they leave to take their baby home.",
        quoteType: "NC",
      },
      {
        id: 3120,
        point: "We are good at boosting the morale with and for each other.",
        quoteType: "NC",
      },
      {
        id: 3121,
        point:
          "We are a very large, very specialized unit. For the most part, it is a happier aspect of healthcare.",
        quoteType: "NC",
      },
      {
        id: 3122,
        point:
          "We are a specialized area that requires training for both instrumentation and procedure knowledge.  Establishing yourself as a nurse who is willing to speak up for safety, have a surgical conscience regarding sterility and presence inside an OR suite.",
        quoteType: "NC",
      },
      {
        id: 3123,
        point:
          "Specializing care for each mom and baby. Teaching new moms how to care for their infants.",
        quoteType: "NC",
      },
      {
        id: 3124,
        point:
          "Our patients as new mothers with a newborn are in need of much education in a short period of time. We are constantly teaching and instructing patient on self care and infant care.",
        quoteType: "NC",
      },
      {
        id: 3125,
        point:
          "On night shift we have very strong teamwork which provides reassurance that you're never alone whether in an emergency, a busy shift or just needing a hand with a task or question.",
        quoteType: "NC",
      },
      {
        id: 3126,
        point:
          "New parents have lots of questions and often need one on one instruction/demonstration of newborn care.  Postpartum moms are emotional and need extra support in the first few days especially.",
        quoteType: "NC",
      },
      {
        id: 3126,
        point:
          "I love my unit, the nurses that work on it and my director.  She is very supportive and listens to the nurses and cares about our patients and staff.",
        quoteType: "NC",
      },
      {
        id: 3235,
        point:
          "A team approach to patient care. Family atmosphere. Dedication to the specialty of perioperative nursing.",
        quoteType: "NM",
      },
      {
        id: 3236,
        point:
          "All of our clinics allow for nurses to work at top of license with great autonomy.",
        quoteType: "NM",
      },
      {
        id: 3237,
        point:
          "There is a lot of pre-op teaching that is extensive (possible tube feeds, no voice, etc).  Multiple post-op visits to remove drains and patient progression",
        quoteType: "NM",
      },
      {
        id: 3238,
        point: "We are like a family and enjoy working and joking together.",
        quoteType: "NM",
      },
      {
        id: 3239,
        point:
          "We are the voice not only for the patient, but for the CC and its care provided to our patients.",
        quoteType: "NM",
      },
      {
        id: 3240,
        point:
          "We have two patients. Not only are we assessing newborns who cant speak for themselves we are teaching patients how to care for themselves as well as their newborns. It is also vital to teach the significant others.",
        quoteType: "NM",
      },
      {
        id: 3241,
        point:
          "We provide care to many disciplines in addition to surgery patients, including pre and post op care for Interventional Radiology, GI, cardiology procedures and pulmonology procedures.  We assist anesthesia in performing pre op peripheral nerve blocks.",
        quoteType: "NM",
      },
      {
        id: 3241,
        point: "We feel like we are an integral part of the surgical team.",
        quoteType: "NM",
      },
    ],
  },
  // {
  //   id: 2006,
  //   heading: "Low Schedule Flexibility / High Patient Acuity",
  //   section: "Training",
  //   schedule: "Low",
  //   acuity: "High",
  //   bulletPoints: [
  //     {
  //       id: 3127,
  //       point:
  //         "It is the only one in all of Cleveland Clinic that is dedicated to detoxing patients.",
  //       quoteType: "NC",
  //     },
  //     {
  //       id: 3128,
  //       point:
  //         "My unit is unique because surgery is a different kind of nursing. It's something you don't really learn in nursing school. It's 4-5 different teams focusing on one patient and ensuring they have a smooth surgery versus 4-5 different teams focusing on 4 plus patients at a time.",
  //       quoteType: "NC",
  //     },
  //     {
  //       id: 3129,
  //       point:
  //         "Only unit that takes ventilator dependent patients, special certifications are required (NIHSS, epilepsy).",
  //       quoteType: "NC",
  //     },
  //     {
  //       id: 3130,
  //       point: "Only unit with vent patients, frequent stroke/trach patients.",
  //       quoteType: "NC",
  //     },
  //     {
  //       id: 3131,
  //       point:
  //         "We are a very specialized surgical unit caring for patients with colorectal cancers and other digestive disease issues. Our patients require a lot of follow up care and teaching about new stomas etc as well as education, medications etc.",
  //       quoteType: "NC",
  //     },
  //     {
  //       id: 3132,
  //       point: "We are specialized in comfort care for our patients.",
  //       quoteType: "NC",
  //     },
  //     {
  //       id: 3133,
  //       point:
  //         "We do procedures that other hospitals do not perform anywhere else in the world.",
  //       quoteType: "NC",
  //     },
  //     {
  //       id: 3134,
  //       point:
  //         "We get patients from all over the country who come her to have colorectal surgery.",
  //       quoteType: "NC",
  //     },
  //     {
  //       id: 3135,
  //       point:
  //         "We have a lot of turn around for patient admits.  We admit and discharge double the patients compared to other units",
  //       quoteType: "NC",
  //     },
  //     {
  //       id: 3136,
  //       point:
  //         "We have multiple experienced nurses who are excellent resources for new RNs. There is a protocol for several of the surgeries that our patients receive so RNs/PCNAs all know what to expect in their care.",
  //       quoteType: "NC",
  //     },
  //     {
  //       id: 3137,
  //       point:
  //         "We have to be stroke certified and we are equipped to take vents amongst other patients.",
  //       quoteType: "NC",
  //     },
  //     {
  //       id: 3138,
  //       point:
  //         "Cardiac surgery runs differently then the other OR specialties due to the volume and complexity of the patients.",
  //       quoteType: "NC",
  //     },
  //     {
  //       id: 3138,
  //       point:
  //         "The constant fluctuation in volume.  No day is ever the same as any other day.  Our volumes and acuities are completely unpredictable and we can never close or go on diversion.  We can never delay accepting a patient for any reason.",
  //       quoteType: "NC",
  //     },
  //     {
  //       id: 3138,
  //       point:
  //         "We are able to care for the children who are sick and educate parents on how to best care for their children after the ER visit.",
  //       quoteType: "NC",
  //     },
  //   ],
  // },
];
