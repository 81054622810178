import "./FindTheRightFitTabs.css";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/pro-regular-svg-icons";
import { FindTheRightFitType } from "../../models/FindTheRightFit";
import { ValueSelector } from "../../models/ValueSelector";
type Props = {
  tabs: FindTheRightFitType;
  experience: ValueSelector | undefined;
  flex: ValueSelector | undefined;
  acuity: ValueSelector | undefined;
  location: ValueSelector | undefined;
  specialty: ValueSelector | undefined;
  facility: ValueSelector | undefined;
};

const FindTheRightFitTabs = ({
  tabs,
  experience,
  flex,
  acuity,
  location,
  specialty,
  facility,
}: Props) => {
  type unit = {
    id: number;
    experience: string;
    location: string;
    name: string;
    facility?: string;
    specialty?: string | string[];
    level?: string;
  };
  const [units, setUnits] = useState<unit[]>(tabs.units);
  const [show, setShow] = useState(false);

  useEffect(() => {
    filterUnits();
  }, [experience, flex, acuity, location, specialty, facility]);

  const filterUnits = () => {
    let unit = tabs.units;
    if (
      experience &&
      experience.level !== undefined &&
      experience.level !== "any"
    ) {
      unit = unit.filter((u) => {
        if (
          u.experience.toLocaleLowerCase() ===
            experience.level.toLocaleLowerCase() ||
          u.experience.toLocaleLowerCase() === "any"
        ) {
          return true;
        }
      });
      setUnits(unit);
    }

    if (location && location.level !== undefined && location.level !== "any") {
      unit = unit.filter(
        (u) =>
          u.location.toLocaleLowerCase() === location.level.toLocaleLowerCase()
      );
      setUnits(unit);
    }
    if (
      specialty &&
      specialty.level !== undefined &&
      specialty.level !== "any"
    ) {
      unit = unit.filter((u) => {
        if (typeof u.specialty === "string") {
          return u.specialty.toLowerCase() === specialty.level.toLowerCase();
        } else if (Array.isArray(u.specialty)) {
          return u.specialty.includes(specialty.level);
        }
      });
      setUnits(unit);
    }
    if (facility && facility.level !== undefined && facility.level !== "any") {
      unit = unit.filter((u) => {
        if (typeof u.facility === "string") {
          return (
            u.facility.toLocaleLowerCase() ===
            facility.level.toLocaleLowerCase()
          );
        }
      });
      setUnits(unit);
    } else if (
      location &&
      location.level === "any" &&
      experience &&
      experience.level === "any" &&
      specialty &&
      specialty.level === "any" &&
      facility &&
      facility.level === "any"
    ) {
      setUnits(tabs.units);
    }
  };

  const handleClick = () => {
    if (show) setShow(false);
    else setShow(true);
  };

  return (
    <>
      {units && units.length > 0 ? (
        <div className="right-fit-tab-container" onClick={handleClick}>
          <div className="right-fit-tab-text-container">
            <h3>{tabs.heading}</h3>
            {show ? (
              <FontAwesomeIcon className="fa-minus" icon={faMinus} />
            ) : (
              <FontAwesomeIcon className="fa-plus" icon={faPlus} />
            )}
          </div>
          <div
            className={`right-fit-expanded-container ${
              show ? null : `hide-container`
            }`}
          >
            <div className="ideal-candidate-container">
              <h4>The Ideal Candidate</h4>
              {tabs.idealCandidate ? (
                <ul>
                  {tabs.idealCandidate.map((bullet: string, index: number) => (
                    <div key={index}>
                      <li>{bullet}</li>
                    </div>
                  ))}
                </ul>
              ) : null}
            </div>
            <div className="example-units-container">
              <h4>Units</h4>
              {units && units.length > 0 ? (
                <ul>
                  {units.map((bullet: unit, index: number) => (
                    <div key={index}>
                      <li>
                        {bullet.name}
                        <span>
                          {", "}
                          {bullet.specialty}
                        </span>
                        <span>
                          {", "}
                          {bullet.facility}
                        </span>
                        <span>
                          {", "}
                          {bullet.location}
                        </span>
                      </li>

                      {/* COULD PUT COMPONENT HERE TO PROVIDE "MORE" INFO */}
                    </div>
                  ))}
                </ul>
              ) : null}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
export default FindTheRightFitTabs;
