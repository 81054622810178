import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPerson,
  faUpRightFromSquare,
} from "@fortawesome/pro-regular-svg-icons";
import "./StartTheStory.css";

import earlyCareerMap from "../../../assets/Mind-Maps-Mind-Map-Early-Career-Candidate-Small.jpg";
import experiencedMap from "../../../assets/Mind-Maps-Mind-Map-Experienced-Candidate-Small.jpg";
import newGradMap from "../../../assets/Mind-Maps-Mind-Map-New-Grad-Candidate-Small.png";
import earlyCareerThumbnail from "../../../assets/Mind-Maps-Early-Career-Candidate-Thumbnail.png";
import experiencedThumbnail from "../../../assets/MindMaps-Experienced-Candidate-Thumbnail.png";
import newGradThumbnail from "../../../assets/Mind-Maps-New-Grad-Candidate-Thumbnail.png";
import newGradPdf from "../../../assets/Mind-Maps-Mind-Map-New-Grad-Candidate.pdf";
import earlyCareerPdf from "../../../assets/Mind-Maps-Mind-Map-Early-Career-Candidate.pdf";
import experiencedPdf from "../../../assets/Mind-Maps-Mind-Map-Experienced-Candidate.pdf";

const StartTheStory = () => {
  const [content, setContent] = useState([
    {
      id: 100,
      title: "New Grad",
      src: newGradMap,
      thumbnailSrc: newGradThumbnail,
      pdfSrc: newGradPdf,
      active: true,
    },
    {
      id: 101,
      title: "Early Career Nurse",
      src: earlyCareerMap,
      thumbnailSrc: earlyCareerThumbnail,
      pdfSrc: earlyCareerPdf,
      active: false,
    },
    {
      id: 102,
      title: "Experienced Nurse",
      src: experiencedMap,
      thumbnailSrc: experiencedThumbnail,
      pdfSrc: experiencedPdf,
      active: false,
    },
  ]);

  const selectPhoto = (id: number) => {
    setContent(
      [...content].map((object) => {
        if (object.id === id) {
          return {
            ...object,
            active: true,
          };
        } else {
          return {
            ...object,
            active: false,
          };
        }
      })
    );
  };

  return (
    <div className="start-the-story-container">
      <h1>Start the Story</h1>
      <div className="start-the-story-text-container">
        <p>
          Telling the story starts with understanding the candidate’s needs,
          wants, desires, thoughts and feelings about their reason for
          considering a new role at Cleveland Clinic.
        </p>
        <p>
          Mind Maps help us understand What Matters Most to our candidates,
          click below to learn more:
        </p>
      </div>
      <div className="multimedia-container">
        <div className="the-candidate-image-container">
          <div className="the-candidate-text-container">
            <div className="the-story-candidate-header-container">
              <h3>
                <FontAwesomeIcon
                  icon={faPerson}
                  className="the-story-fa-person"
                />
                The Candidate
              </h3>
            </div>
            <p>
              These “Mind Maps” are a visualization of all the many factors a
              candidate is thinking about.
            </p>
          </div>
          {content.map((c) => (
            <div
              className={`each-mind-map-container ${
                c.active ? `featured-container` : null
              }`}
              onClick={() => selectPhoto(c.id)}
              key={c.id}
            >
              <img src={c.thumbnailSrc} className="content-thumbnail" alt="" />
              <div
                className={`content-title ${
                  c.active ? `featured` : `not-featured`
                }`}
              >
                <p>{c.title}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="mind-map-container">
          <div className="mind-map-text-container">
            <p>
              <strong>New Grad Nurse:</strong> Less than 1 year of professional
              nursing experience
            </p>
            <p>
              <strong>Early Career Nurse:</strong> 1 – 3 years of professional
              nursing experience
            </p>
            <p>
              <strong>Experienced Nurse:</strong> 4 + years of professional
              nursing experience
            </p>
          </div>
          <div className="featured-photo">
            {content.map((c) =>
              c.active ? (
                <div key={c.id}>
                  <a href={c.pdfSrc} download>
                    <img src={c.src} key={c.id} alt="Mind Map" />

                    <div className="photo-overlay">
                      <div className="photo-overlay-text">
                        <p>
                          <FontAwesomeIcon
                            icon={faUpRightFromSquare}
                            className="fa-open-tab"
                          />
                          Download Mind Map
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
              ) : null
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default StartTheStory;
