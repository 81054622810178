import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/pro-regular-svg-icons";

import "./CloseTheCallTabs.css";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {CloseTheCallTabsType} from "../../models/CloseTheCallTabsType"
type Props = {
  tabs: CloseTheCallTabsType
}

const CloseTheCallTabs = ({tabs}: Props) => {

  const [show, setShow] = useState(false);
  const handleClick = () => {
    if (show) setShow(false);
    else setShow(true);
  };
  return (
    <div className="tab-container" onClick={handleClick}>
      <div className="tab-text-container">
        <h3>{tabs.title}</h3>
        <p className="overview">
          <strong>Overview: </strong>
          {tabs.overview}
        </p>
        {show ? (
          <FontAwesomeIcon className="fa-minus" icon={faMinus as IconProp} />
        ) : (
          <FontAwesomeIcon className="fa-plus" icon={faPlus as IconProp} />
        )}
      </div>
      <div className={`expanded-tab ${show ? null : `hide`}`}>
        <div className="tab-section first-section">
          <h4>{tabs.firstSectionTitle}</h4>
          <p>{tabs.firstSectionInfo}</p>
        </div>
        <div className="tab-section second-section">
          <h4>{tabs.secondSectionTitle}</h4>
          <p>{tabs.secondSectionInfo}</p>
        </div>
        <div className="tab-section third-section">
          <h4>{tabs.thirdSectionTitle}</h4>
          {Array.isArray(tabs.thirdSectionInfo) ? (
            <ul>
              {tabs.thirdSectionInfo.map((info: {id:number, content:string}) => (
                <li key={info.id}>{info.content}</li>
              ))}
            </ul>
          ) : (
            <p>{tabs.thirdSectionInfo}</p>
          )}
          {tabs.thirdSectionMoreInfo ? (
            <p>{tabs.thirdSectionMoreInfo}</p>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default CloseTheCallTabs;
