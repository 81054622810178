import React, { useState, useEffect } from "react";
import FindTheRightFitTabSelector from "./Selectors/FindTheRightFitTabSelector";
import FindTheRightFitUnitSelector from "./Selectors/FindTheRightFitUnitSelector";
import "./FindTheRightFit.css";
import personaTemplate from "../../../assets/Persona-Templates.pptx";
import FindTheRightFitTabs from "../FindTheRightFitTabs/FindTheRightFitTabs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPerson,
  faCircleInfo,
  faLampStreet,
} from "@fortawesome/pro-regular-svg-icons";
import { ValueSelector } from "../../models/ValueSelector";
import { FindTheRightFitType } from "../../models/FindTheRightFit";
import { FindTheRightFitData } from "../../data/FindTheRightFitData";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

type Props = {
  isAuth: () => void;
};

const FindTheRightFit = ({ isAuth }: Props) => {
  const findTheRightFit: FindTheRightFitType[] = FindTheRightFitData;
  useEffect(() => {
    console.log("called");
    isAuth();
  }, []);

  const experienceValue = [
    {
      id: 907,
      category: "experience",
      level: "any",
      label: "Any Experience Level Nurse",
    },
    { id: 908, category: "experience", level: "new", label: "New Grad" },
    {
      id: 909,
      category: "experience",
      level: "experienced",
      label: "Experienced Nurse",
    },
  ];
  const flexValue = [
    { id: 911, category: "flex", level: "any", label: "Any Flexibility" },
    { id: 912, category: "flex", level: "low", label: "Low Flexibility" },
    { id: 913, category: "flex", level: "medium", label: "Medium Flexibility" },
    { id: 914, category: "flex", level: "high", label: "High Flexibility" },
  ];

  const acuityValue = [
    { id: 916, category: "acuity", level: "any", label: "Any Acuity" },
    { id: 917, category: "acuity", level: "low", label: "Low" },
    { id: 919, category: "acuity", level: "high", label: "High" },
  ];
  const locationValue = [
    { id: 922, category: "location", level: "any", label: "Anywhere" },
    { id: 923, category: "location", level: "Akron, OH", label: "Akron, OH" },
    { id: 924, category: "location", level: "Avon, OH", label: "Avon, OH" },
    {
      id: 925,
      category: "location",
      level: "Beachwood, OH",
      label: "Beachwood, OH",
    },
    {
      id: 926,
      category: "location",
      level: "Brunswick, OH",
      label: "Brunswick, OH",
    },
    { id: 927, category: "location", level: "Canton, OH", label: "Canton, OH" },
    {
      id: 928,
      category: "location",
      level: "Cleveland, OH",
      label: "Cleveland, OH",
    },
    { id: 929, category: "location", level: "Dover, OH", label: "Dover, OH" },
    { id: 930, category: "location", level: "Euclid, OH", label: "Euclid, OH" },
    {
      id: 931,
      category: "location",
      level: "Garfield Heights, OH",
      label: "Garfield Heights, OH",
    },
    {
      id: 9311,
      category: "location",
      level: "Green, OH",
      label: "Green, OH",
    },
    {
      id: 932,
      category: "location",
      level: "Independence, OH",
      label: "Independence, OH",
    },
    {
      id: 933,
      category: "location",
      level: "Lakewood, OH",
      label: "Lakewood, OH",
    },
    { id: 934, category: "location", level: "Lorain, OH", label: "Lorain, OH" },
    {
      id: 935,
      category: "location",
      level: "Mayfeild Heights, OH",
      label: "Mayfeild Heights, OH",
    },
    { id: 936, category: "location", level: "Medina, OH", label: "Medina, OH" },
    { id: 937, category: "location", level: "Norton, OH", label: "Norton, OH" },
    {
      id: 938,
      category: "location",
      level: "Sandusky, OH",
      label: "Sandusky, OH",
    },
    {
      id: 939,
      category: "location",
      level: "Sheffield, OH",
      label: "Sheffield, OH",
    },
    { id: 940, category: "location", level: "solon", label: "Solon, OH" },
    {
      id: 941,
      category: "location",
      level: "Strongsville, OH",
      label: "Strongsville, OH",
    },
    { id: 942, category: "location", level: "Stuart, FL", label: "Stuart, FL" },
    {
      id: 943,
      category: "location",
      level: "Twinsburg, OH",
      label: "Twinsburg, OH",
    },
    {
      id: 944,
      category: "location",
      level: "Vero Beach, FL",
      label: "Vero Beach, FL",
    },
    {
      id: 945,
      category: "location",
      level: "Warrensville Heights, OH",
      label: "Warrensville Heights, OH",
    },
    {
      id: 946,
      category: "location",
      level: "West Palm Beach, FL",
      label: "West Palm Beach, FL",
    },
    {
      id: 947,
      category: "location",
      level: "Westlake, OH",
      label: "Westlake, OH",
    },
    { id: 948, category: "location", level: "Weston, FL", label: "Weston, FL" },
    {
      id: 949,
      category: "location",
      level: "Willoughby Hills, OH",
      label: "Willoughby Hills, OH",
    },
    {
      id: 950,
      category: "location",
      level: "Wooster, OH",
      label: "Wooster, OH",
    },
  ];
  const specialtyValue = [
    {
      id: 951,
      category: "specialty",
      level: "any",
      label: "Any Specialty",
    },
    {
      id: 1050,
      category: "specialty",
      level: "Ambulatory",
      label: "Ambulatory",
    },
    {
      id: 952,
      category: "specialty",
      level: "Behavioral Health",
      label: "Behavioral Health",
    },
    {
      id: 953,
      category: "specialty",
      level: "Bone Marrow Transplant",
      label: "Bone Marrow Transplant",
    },
    {
      id: 954,
      category: "specialty",
      level: "Cardiac",
      label: "Cardiac",
    },
    {
      id: 955,
      category: "specialty",
      level: "Cardiac Short Stay",
      label: "Cardiac Short Stay",
    },
    {
      id: 956,
      category: "specialty",
      level: "Cardiac Surgery Stepdown",
      label: "Cardiac Surgery Stepdown",
    },
    {
      id: 957,
      category: "specialty",
      level: "Cardiology & Heart Failure Stepdown",
      label: "Cardiology & Heart Failure Stepdown",
    },
    {
      id: 958,
      category: "specialty",
      level: "Children's Rehab",
      label: "Children's Rehab",
    },
    {
      id: 959,
      category: "specialty",
      level: "Chronic Disease",
      label: "Chronic Disease",
    },
    {
      id: 971,
      category: "specialty",
      level: "Digestive Disease",
      label: "Digestive Disease",
    },

    {
      id: 960,
      category: "specialty",
      level: "Emergency",
      label: "Emergency",
    },
    {
      id: 961,
      category: "specialty",
      level: "Hematology Oncology",
      label: "Hematology Oncology",
    },
    {
      id: 962,
      category: "specialty",
      level: "ICU",
      label: "ICU",
    },
    {
      id: 963,
      category: "specialty",
      level: "Imaging",
      label: "Imaging",
    },
    {
      id: 964,
      category: "specialty",
      level: "Labor and Delivery",
      label: "Labor and Delivery",
    },
    {
      id: 965,
      category: "specialty",
      level: "Medical Surgical",
      label: "Medical Surgical",
    },
    {
      id: 966,
      category: "specialty",
      level: "Medical Telemetry",
      label: "Medical Telemetry",
    },
    {
      id: 967,
      category: "specialty",
      level: "Neuro Med Surg",
      label: "Neuro Med Surg",
    },
    {
      id: 968,
      category: "specialty",
      level: "Neuro Stepdown",
      label: "Neuro Stepdown",
    },
    {
      id: 969,
      category: "specialty",
      level: "Observation",
      label: "Observation",
    },
    {
      id: 970,
      category: "specialty",
      level: "Orthopaedics",
      label: "Orthopaedics",
    },
    // {
    //   id: 971,
    //   category: "specialty",
    //   level: "Other ICU area",
    //   label: "Other ICU area",
    // },
    {
      id: 972,
      category: "specialty",
      level: "Other non-ICU area",
      label: "Other non-ICU area",
    },
    {
      id: 973,
      category: "specialty",
      level: "Peds Emergency",
      label: "Peds Emergency",
    },
    {
      id: 974,
      category: "specialty",
      level: "Peds ICU",
      label: "Peds ICU",
    },
    {
      id: 975,
      category: "specialty",
      level: "Post-Surgical",
      label: "Post-Surgical",
    },
    {
      id: 976,
      category: "specialty",
      level: "Progressive Care Cardiac",
      label: "Progressive Care Cardiac",
    },
    {
      id: 977,
      category: "specialty",
      level: "Renal",
      label: "Renal",
    },
    {
      id: 978,
      category: "specialty",
      level: "Respiratory Stepdown",
      label: "Respiratory Stepdown",
    },
    // {
    //   id: 979,
    //   category: "specialty",
    //   level: "School Age",
    //   label: "School Age",
    // },
    {
      id: 980,
      category: "specialty",
      level: "Stroke",
      label: "Stroke",
    },
    {
      id: 981,
      category: "specialty",
      level: "Sub-Acute & Rehab",
      label: "Sub-Acute & Rehab",
    },
    {
      id: 982,
      category: "specialty",
      level: "Surgery",
      label: "Surgery",
    },
    {
      id: 983,
      category: "specialty",
      level: "Telemetry",
      label: "Telemetry",
    },
    // {
    //   id: 984,
    //   category: "specialty",
    //   level: "Thoracic Surgery Stepdown",
    //   label: "Thoracic Surgery Stepdown",
    // },
    {
      id: 985,
      category: "specialty",
      level: "Transplant",
      label: "Transplant",
    },
    {
      id: 1051,
      category: "specialty",
      level: "Travel and Float",
      label: "Travel and Float",
    },
    {
      id: 1052,
      category: "specialty",
      level: "Women's Health",
      label: "Women's Health",
    },
    // {
    //   id: 986,
    //   category: "specialty",
    //   level: "Trauma Med/Surg",
    //   label: "Trauma Med/Surg",
    // },
    // {
    //   id: 987,
    //   category: "specialty",
    //   level: "Vascular Surgery Stepdown",
    //   label: "Vascular Surgery Stepdown",
    // },
  ];
  const facilityValue = [
    { id: 988, category: "facility", level: "any", label: "Any Facility" },
    {
      id: 989,
      category: "facility",
      level: "Akron General Medical Center",
      label: "Akron General Medical Center",
    },
    {
      id: 990,
      category: "facility",
      level: "Akron Medical Office",
      label: "Akron Medical Office",
    },
    {
      id: 991,
      category: "facility",
      level: "Avon Hospital",
      label: "Avon Hospital",
    },
    {
      id: 992,
      category: "facility",
      level: "Beachwood Fam Hlth Surgery Ctr",
      label: "Beachwood Fam Hlth Surgery Ctr",
    },
    {
      id: 993,
      category: "facility",
      level: "Brunswick Family Hlth Ctr",
      label: "Brunswick Family Hlth Ctr",
    },
    {
      id: 994,
      category: "facility",
      level: "CC Administrative Campus",
      label: "CC Administrative Campus",
    },
    {
      id: 996,
      category: "facility",
      level: "CC Florida West Palm Beach",
      label: "CC Florida West Palm Beach",
    },
    {
      id: 997,
      category: "facility",
      level: "CC Florida Weston",
      label: "CC Florida Weston",
    },
    {
      id: 998,
      category: "facility",
      level: "Childrens Hospital Rehab",
      label: "Childrens Hospital Rehab",
    },
    {
      id: 999,
      category: "facility",
      level: "Cleveland Clinic Indian River",
      label: "Cleveland Clinic Indian River",
    },
    {
      id: 1000,
      category: "facility",
      level: "Cleveland Clinic Main Campus",
      label: "Cleveland Clinic Main Campus",
    },
    {
      id: 995,
      category: "facility",
      level: "Cleveland Clinic Martin Health",
      label: "Cleveland Clinic Martin Health",
    },
    {
      id: 1001,
      category: "facility",
      level: "Cleveland Clinic Mercy Hospital",
      label: "Cleveland Clinic Mercy Hospital",
    },
    {
      id: 1002,
      category: "facility",
      level: "Cleveland Clinic Union Hospital",
      label: "Cleveland Clinic Union Hospital",
    },
    {
      id: 1003,
      category: "facility",
      level: "Euclid Hospital",
      label: "Euclid Hospital",
    },
    {
      id: 1034,
      category: "facility",
      level: "Fairview Hospital",
      label: "Fairview Hospital",
    },
    {
      id: 1004,
      category: "facility",
      level: "Health and Wellness Bath",
      label: "Health and Wellness Bath",
    },
    {
      id: 1005,
      category: "facility",
      level: "Health and Wellness Green",
      label: "Health and Wellness Green",
    },
    {
      id: 1006,
      category: "facility",
      level: "Health and Wellness Stow",
      label: "Health and Wellness Stow",
    },
    {
      id: 1007,
      category: "facility",
      level: "Hillcrest Hospital",
      label: "Hillcrest Hospital",
    },
    {
      id: 1008,
      category: "facility",
      level: "Independence Family Hlth Ctr",
      label: "Independence Family Hlth Ctr",
    },
    {
      id: 1009,
      category: "facility",
      level: "Lakewood Family Health Center",
      label: "Lakewood Family Health Center",
    },
    {
      id: 1010,
      category: "facility",
      level: "Landerbrook Ofc and Endo Ctr",
      label: "Landerbrook Ofc and Endo Ctr",
    },
    {
      id: 1011,
      category: "facility",
      level: "Lorain FHC Surgery",
      label: "Lorain FHC Surgery",
    },
    {
      id: 1012,
      category: "facility",
      level: "Lutheran Hospital",
      label: "Lutheran Hospital",
    },
    {
      id: 1013,
      category: "facility",
      level: "Marymount Hospital",
      label: "Marymount Hospital",
    },
    {
      id: 1015,
      category: "facility",
      level: "Medina Hospital",
      label: "Medina Hospital",
    },
    {
      id: 1016,
      category: "facility",
      level: "Medina Medical Office Building",
      label: "Medina Medical Office Building",
    },
    {
      id: 1017,
      category: "facility",
      level: "NCCC Sandusky Campus",
      label: "NCCC Sandusky Campus",
    },
    {
      id: 1019,
      category: "facility",
      level: "North Ohio Gastroenterology",
      label: "North Ohio Gastroenterology",
    },
    {
      id: 1021,
      category: "facility",
      level: "Sheffield Family Health Center",
      label: "Sheffield Family Health Center",
    },
    {
      id: 1022,
      category: "facility",
      level: "Solon Call Ctr Access to Care",
      label: "Solon Call Ctr Access to Care",
    },
    {
      id: 1023,
      category: "facility",
      level: "Solon Family Health Center",
      label: "Solon Family Health Center",
    },
    {
      id: 1025,
      category: "facility",
      level: "South Pointe Hospital",
      label: "South Pointe Hospital",
    },
    {
      id: 1026,
      category: "facility",
      level: "Strongsville Fam Hlth Surg Ctr",
      label: "Strongsville Fam Hlth Surg Ctr",
    },
    { id: 1027, category: "facility", level: "Summit GI", label: "Summit GI" },
    {
      id: 1028,
      category: "facility",
      level: "The Richard Jacobs Hlth Ctr",
      label: "The Richard Jacobs Hlth Ctr",
    },
    {
      id: 1031,
      category: "facility",
      level: "Twinsburg Family Hlth Ctr",
      label: "Twinsburg Family Hlth Ctr",
    },
    {
      id: 1032,
      category: "facility",
      level: "Willoughby Hills Fam Hlth Ctr",
      label: "Willoughby Hills Fam Hlth Ctr",
    },
    {
      id: 1033,
      category: "facility",
      level: "Wooster Family Health Center",
      label: "Wooster Family Health Center",
    },
  ];
  const [tabs, setTabs] = useState<FindTheRightFitType[]>(findTheRightFit);
  const [experience, setExperience] = useState<ValueSelector | undefined>(
    experienceValue[0]
  );
  const [flex, setFlex] = useState<ValueSelector | undefined>(flexValue[0]);
  const [acuity, setAcuity] = useState<ValueSelector | undefined>(
    acuityValue[0]
  );
  const [location, setLocation] = useState<ValueSelector | undefined>(
    locationValue[0]
  );
  const [specialty, setSpecialty] = useState<ValueSelector | undefined>(
    specialtyValue[0]
  );
  const [facility, setFacility] = useState<ValueSelector | undefined>(
    facilityValue[0]
  );
  const [filtersApplied, setFiltersApplied] = useState(false);

  const handleSetValue = (id: number, category: string) => {
    try {
      setFiltersApplied(true);
      if (category === "experience") {
        const targetValue = experienceValue.find((target) => target.id === id);
        setExperience(targetValue);
      } else if (category === "flex") {
        const targetValue = flexValue.find((target) => target.id === id);
        setFlex(targetValue);
      } else if (category === "acuity") {
        const targetValue = acuityValue.find((target) => target.id === id);
        setAcuity(targetValue);
      } else if (category === "location") {
        const targetValue = locationValue.find((target) => target.id === id);
        setLocation(targetValue);
      } else if (category === "specialty") {
        const targetValue = specialtyValue.find((target) => target.id === id);
        setSpecialty(targetValue);
      } else if (category === "facility") {
        const targetValue = facilityValue.find((target) => target.id === id);
        setFacility(targetValue);
      }
    } catch (e: unknown) {
      if (typeof e === "string") {
        console.error(e);
      } else if (e instanceof Error) {
        console.error(e.message);
      }
    }
  };

  const filterTabsByFlex = (flex: string) => {
    let filteredTabs: FindTheRightFitType[] = findTheRightFit.filter(
      (tab) => tab.schedule.toLocaleLowerCase() === flex.toLocaleLowerCase()
    );
    if (flex === "any") {
      filteredTabs = findTheRightFit;
    }
    if (acuity && acuity.level !== "any") {
      filteredTabs = filteredTabs.filter(
        (tab) =>
          tab.acuity.toLocaleLowerCase() === acuity.level.toLocaleLowerCase()
      );
    }
    setTabs(filteredTabs);
  };
  const filterTabsByAcuity = (acuity: string) => {
    let filteredTabs: FindTheRightFitType[] = findTheRightFit.filter(
      (tab) => tab.acuity.toLocaleLowerCase() === acuity.toLocaleLowerCase()
    );
    if (acuity === "any") {
      filteredTabs = findTheRightFit;
    }
    if (flex && flex.level !== "any") {
      filteredTabs = filteredTabs.filter(
        (tab) =>
          tab.schedule.toLocaleLowerCase() === flex.level.toLocaleLowerCase()
      );
    }
    setTabs(filteredTabs);
  };

  const clearFilters = () => {
    setTabs(findTheRightFit);
    setExperience(experienceValue[0]);
    setFlex(flexValue[0]);
    setAcuity(acuityValue[0]);
    setLocation(locationValue[0]);
    setSpecialty(specialtyValue[0]);
    setFacility(facilityValue[0]);
    setFiltersApplied(false);
  };

  return (
    <div className="find-the-right-fit-container">
      <h1>Find the Right Fit</h1>
      <div className="find-the-right-fit-text-container">
        <p>Is this candidate a good fit for this position?</p>
        <p>
          Did they apply to a job you&apos;ve already filled, but you want to
          bring them into Cleveland Clinic?
        </p>
      </div>
      <div className="matching-units-container">
        <div className="matching-units-content-container">
          <h2>Matching Units Discovery</h2>
          <p>
            Match the candidate to the right positions based on what you know
            about them:
          </p>
          <div className="filter-controls-and-tabs-container">
            <div className="left-filter-controls">
              <div className="left-filter-controls-content">
                <h3>
                  <FontAwesomeIcon
                    icon={faPerson as IconProp}
                    className="find-the-right-fir-fa-person"
                  />
                  The Candidate
                </h3>
                <div className="making-assumptions-container">
                  <FontAwesomeIcon
                    icon={faCircleInfo as IconProp}
                    className="making-assumptions-icon"
                  />
                  <p>Making assumptions?</p>
                  <p>
                    Refer to a{" "}
                    <a href={personaTemplate} download>
                      Persona
                    </a>{" "}
                    template!
                  </p>
                </div>
                <p className="they-are-a-header">They are a</p>
                <FindTheRightFitUnitSelector
                  listValue={experienceValue}
                  stateValue={experience}
                  handleSetValue={handleSetValue}
                />

                <p className="they-want-to-care-header">
                  They want schedule with
                </p>
                <FindTheRightFitTabSelector
                  listValue={flexValue}
                  stateValue={flex}
                  handleSetValue={handleSetValue}
                  filter={filterTabsByFlex}
                />
                <p className="they-want-to-care-header">
                  The patient acuity that best matches their Persona is
                </p>
                <FindTheRightFitTabSelector
                  listValue={acuityValue}
                  stateValue={acuity}
                  handleSetValue={handleSetValue}
                  filter={filterTabsByAcuity}
                />
                <p className="they-want-to-work-at-header">
                  They want to work with
                </p>
                <FindTheRightFitUnitSelector
                  listValue={specialtyValue}
                  stateValue={specialty}
                  handleSetValue={handleSetValue}
                />
                <p className="they-want-to-work-at-header">
                  The facility that best matches their Persona is
                </p>
                <FindTheRightFitUnitSelector
                  listValue={facilityValue}
                  stateValue={facility}
                  handleSetValue={handleSetValue}
                />
                <p className="they-want-to-work-at-header">
                  They want to work in
                </p>
                <FindTheRightFitUnitSelector
                  listValue={locationValue}
                  stateValue={location}
                  handleSetValue={handleSetValue}
                />
              </div>
              <div
                className={`ftrf-clear-filters-box
                ${filtersApplied ? `clear-filter-enabled` : null}
                `}
              >
                <p onClick={clearFilters}>
                  <FontAwesomeIcon
                    icon={faLampStreet as IconProp}
                    className="fa-lamp"
                  />{" "}
                  Clear Filters
                </p>
              </div>
            </div>
            <div className="unit-tabs">
              <div className="no-example-units-container">
                <p>No units available. Please make another selection.</p>
              </div>
              {tabs ? (
                tabs.map((tab, index) => (
                  <div key={index} className="find-the-right-fit-tab-container">
                    <FindTheRightFitTabs
                      tabs={tab}
                      key={tab.id}
                      experience={experience}
                      flex={flex}
                      acuity={acuity}
                      location={location}
                      specialty={specialty}
                      facility={facility}
                    />
                  </div>
                ))
              ) : (
                <h2>No content</h2>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FindTheRightFit;
