import React, { useState, useEffect } from "react";
import GetToYesBulletPoints from "../GetToYesBulletPoints/GetToYesBulletPoints";
import "./GetToYesTabs.css";
import { GetToYesTabsType } from "../../../models/GetToYesTabs";

type Props = {
  tab: GetToYesTabsType;
  keyword: string | number | readonly string[] | undefined;
};
const GetToYesTabs = ({ tab, keyword }: Props) => {
  type Quotes = {
    id: number;
    point: string;
    quoteType: string;
  };
  const [showAll, setShowAll] = useState<boolean>(false);
  const [quotes, setQuotes] = useState<Quotes[] | undefined>(tab.bulletPoints);
  const toggleShowTabs = () => {
    if (showAll) setShowAll(false);
    else setShowAll(true);
  };
  const filterQuotesByKeyword = () => {
    let newQuotes = tab.bulletPoints;
    if (keyword && typeof keyword === "string" && keyword.length > 0) {
      newQuotes = newQuotes?.filter(
        (quote) => quote.point.toLowerCase().indexOf(keyword.toLowerCase()) > -1
      );
    } else {
      newQuotes = tab.bulletPoints;
    }
    setQuotes(newQuotes);
  };
  useEffect(() => {
    filterQuotesByKeyword();
  }, [keyword, tab]);

  return (
    <>
      {quotes && quotes.length > 0 ? (
        <div className="each-gty-topics-tabs" onClick={toggleShowTabs}>
          <div className={`each-gty-topics-tab-heading `}>
            <h3>{tab.heading}</h3>
            {/* <div className="each-gty-topics-tab-section">
          <p>{tab.section}</p>
        </div> */}
          </div>
          <div className="gty-bullet-points-container">
            <div
              className={`gty-both-quotes-container  ${
                tab &&
                quotes &&
                quotes?.findIndex((point) => point.quoteType === "NC") !== -1
                  ? null
                  : `no-content`
              }
              `}
            >
              {tab &&
              quotes &&
              quotes?.findIndex((point) => point.quoteType === "NC") !== -1 ? (
                <h3>Nurse Caregiver Quotes</h3>
              ) : null}

              {quotes
                ? quotes.map(
                    (
                      point: { id: number; point: string; quoteType: string },
                      i: number
                    ) => {
                      if (point.quoteType === "NC") {
                        return (
                          <ul key={i}>
                            <li>
                              <GetToYesBulletPoints
                                bullet={point}
                                keyword={keyword}
                              />
                            </li>
                          </ul>
                        );
                      }
                    }
                  )
                : null}
            </div>
            <div
              className={`gty-both-quotes-container ${
                tab &&
                quotes &&
                quotes?.findIndex((point) => point.quoteType === "NM") !== -1
                  ? null
                  : `no-content`
              }`}
            >
              {" "}
              {tab &&
              quotes &&
              quotes?.findIndex((point) => point.quoteType === "NM") !== -1 ? (
                <h3>Nurse Manager Quotes</h3>
              ) : null}
              {quotes
                ? quotes.map(
                    (
                      point: { id: number; point: string; quoteType: string },
                      i: number
                    ) => {
                      if (point.quoteType === "NM") {
                        return (
                          <ul key={i}>
                            <li>
                              <GetToYesBulletPoints
                                bullet={point}
                                keyword={keyword}
                              />
                            </li>
                          </ul>
                        );
                      }
                    }
                  )
                : null}
            </div>
          </div>
        </div>
      ) : (
        <div className="no-content"></div>
      )}
    </>
  );
};

export default GetToYesTabs;

/*
{bullet &&
  keyword !== undefined &&
  typeof keyword === "string" &&
  keyword.length > 0 &&
  bullet.point.toLowerCase().indexOf(keyword.toLowerCase()) > -1 ? (
    <p>&quot;{bullet.point}&quot;</p>
  ) : null} */
