import React from "react";
import "./GetToYesBulletPoints.css";

type Props = {
  bullet?: { id: number; point: string };
  keyword: string | number | readonly string[] | undefined;
};
const GetToYesBulletPoints = ({ bullet, keyword }: Props) => {
  // const targetKeywords = ["Critical Care","Seasoned", "Med/Surg","New Grad"]
  const targetKeywords = keyword;

  // const highlightedExperience =(level:string, keyword:string):boolean | void=> {
  //     if(experience) {
  //     if(keyword && level.toLowerCase()=== keyword.toLowerCase()){
  //         return true
  //     } else {
  //         return false
  //     }}
  // }
  // const highlightedUnitGroup =(list:ValueSelector[], keyword:string):boolean | void=> {
  //     if(unitGroupList) {
  //         for(let i = 0;i < list.length; i++){
  //             if(keyword && list[i].level.toLowerCase() === keyword.toLowerCase()){
  //                 return true
  //             }
  //         }
  //         return false
  //    }
  // }

  const getHighlightedText = (
    text: string,
    keywords: string | string[]
  ): string[] | JSX.Element | undefined => {
    if (typeof keywords === "string") {
      const parts = text.split(new RegExp(`(${keywords})`, "gi"));
      return (
        <span>
          {" "}
          {parts.map((part, i) => (
            <span
              key={i}
              className={`${
                part.toLowerCase() === keywords.toLowerCase()
                  ? `key-word`
                  : null
              }  
          
                `}
            >
              {part}
            </span>
          ))}{" "}
        </span>
      );
    }

    // else if(Array.isArray(keywords)){
    //     let prevText:any;
    //     let newText:any;
    //     let nextText: any;
    //     let index:number;
    //     const sortedArray:string[] = [];

    //     //First loop sorts array of 'keywords to highlight' in order how they appear in the target string
    //     for(let i = 0; i < keywords.length; i++){
    //       let word  = new RegExp(`(${keywords[i]})`, 'gi').toString().slice(2, -4).toLowerCase()
    //       if(word.includes('\\')){
    //         word = word.replace('\\','')
    //       }
    //       const firstIndex = text.toLowerCase().indexOf(word)
    //       sortedArray[firstIndex] = keywords[i]
    //     }
    //     //Second loop iterates through the newly sorted array to locate the index of the keyword
    //     for(let i = 0; i < sortedArray.length; i++){
    //     //On first iteration, assign newText and split into array at index of keyword
    //      if (!prevText){
    //         newText  = text.split(new RegExp(`(${sortedArray[i]})`, 'gi'));
    //         index = newText.findIndex((text: string ) => text === sortedArray[i])
    //         //splice jsx object into array
    //         if(index !== undefined && index !== -1) {
    //           newText.splice(index,0, <span key={i} className={`key-word ${experience && highlightedExperience(experience.level, sortedArray[i]) ? `experience-level`: null}
    //           ${unitGroupList && highlightedUnitGroup(unitGroupList, sortedArray[i]) ? `unit-group` : null}
    //           `} ><span>{sortedArray[i]}{" "}</span></span>)
    //         }
    //         //Find index of orginal keyword and remove
    //         index = newText.lastIndexOf(sortedArray[i])
    //         if(index !== -1){
    //           newText.splice(index, 1)
    //         }
    //       }
    //       //On subsequent iterations, finds index of keyword on new 'right-side' of string in the array (aka newText)
    //        else if (prevText){
    //       nextText = typeof prevText === "string" ? prevText.split(new RegExp(`(${sortedArray[i]})`, 'gi')) : undefined;
    //       index = Array.isArray(nextText) ? nextText.findIndex((text) => text === sortedArray[i]) : -1
    //       //splice jsx object into array, then
    //       if(index !== -1 && Array.isArray(nextText)) {
    //         nextText.splice(index,0, <span key={i} className={`key-word ${experience && highlightedExperience(experience.level, sortedArray[i]) ? `experience-level`: null}
    //         ${unitGroupList && highlightedUnitGroup(unitGroupList, sortedArray[i]) ? `unit-group` : null}
    //         `} ><span>{sortedArray[i]}{" "}</span></span>)
    //       }

    //       //Find index of orginal keyword and in new 'nextText' array and remove
    //       index = Array.isArray(nextText) ? nextText.lastIndexOf(sortedArray[i]) : -1
    //       if(index !== -1 && Array.isArray(nextText)){
    //         nextText.splice(index, 1)
    //       }
    //      }
    //      //Once 'new' or subsequent string (aka nextText) has been split and spliced,
    //      //push those elements & remiaing strings in to original array (aka newText)
    //         if(nextText !== undefined && Array.isArray(nextText) && Array.isArray(newText)){
    //           nextText.forEach((el:Element | any) => {
    //             if(Array.isArray(newText)){
    //             newText.push(el)
    //           }
    //           })
    //           nextText = null;
    //         }
    //         //Removes latter part of newText array which is the rest of string to iterate through
    //         if(newText &&  i + 1 < sortedArray.length && Array.isArray(newText)){
    //         prevText = newText.pop()
    //       }
    //        }
    //         return newText;
    //     }
  };

  return (
    <div className="point-container">
      <div className="each-point-container">
        <span className="bullet-point">
          &quot;
          {bullet && keyword && typeof keyword === "string"
            ? getHighlightedText(bullet.point, keyword)
            : bullet && <span className="bullet-point">{bullet.point}</span>}
          &quot;
        </span>
      </div>
    </div>
  );
};

export default GetToYesBulletPoints;

// ${experience && highlightedExperience(experience.level, keywords) ? `experience-level`: null}
//                ${unitGroupList && highlightedUnitGroup(unitGroupList, keywords) ? `unit-group` : null}
